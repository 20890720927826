import React from "react"
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { FinmateSubscription, OrgUser, StripeCustomer } from "client"
import { getOrgUserPrice } from "service/payApi"

interface Props {
  updatedUser: OrgUser
  sub?: FinmateSubscription
  customer?: StripeCustomer
}
const AddPayingSeatInfo = ({ updatedUser, sub, customer }: Props) => {

  const nameEmail = updatedUser?.name ? `${updatedUser?.name}, ${updatedUser?.email}` : `${updatedUser?.email}`

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', marginTop: 1 }}>
      <Typography variant='h6'>Billing Change</Typography>
      <Typography variant="caption">
        {updatedUser?.org_user_plan} plan will be added to {nameEmail}
      </Typography>

      <TableContainer >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow >
              <TableCell component="th" scope="row" sx={{ color: 'darkgreen' }}>
                {updatedUser?.org_user_plan} Plan
              </TableCell>
              <TableCell align={'right'} sx={{ color: 'darkgreen', minWidth: 150 }}>
                +${getOrgUserPrice(sub, updatedUser)} / month
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>

      {customer?.discount &&
        <Typography variant='caption'>Pre-discount price is shown. Your discount will be applied upon saving the change.</Typography>
      }
    </Card>
  )
}

export default AddPayingSeatInfo
