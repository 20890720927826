import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Routes from './routes'
import mixpanel from 'mixpanel-browser'
import { MixpanelProvider } from 'react-mixpanel-browser'
import { config } from './service/api'
import '@fontsource/roboto'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './styles/Theme'
import { StoreContext } from './context/context'
import { RootStore, InitStore } from './store'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { checkAuthStatus } from 'service/authApi'
import { CssBaseline } from '@mui/material'
import './i18n'
import I18nInit from 'components/i18n/I18nInit'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (!config.mixpanelToken)
  console.error('missing mixpanel token')
else
  mixpanel.init(config.mixpanelToken)

root.render(<Main />)

const queryClient = new QueryClient()

function Main() {
  const [rootStore] = useState(() => RootStore.create(InitStore))

  const [isAuthedLoaded, setAuthLoaded] = useState(false)
  useEffect(() => {
    // on page refresh, fbuser is null until firebase is loaded.
    // this makes sure when user signed in, fbuser is never null even on refresh.
    checkAuthStatus()
      .then(() => setAuthLoaded(true))
  }, [])

  if (isAuthedLoaded == false)
    return <></> // wait for firebase auth to load

  return (
    <MixpanelProvider mixpanel={mixpanel}>
      <StoreContext.Provider value={rootStore}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <GoogleOAuthProvider clientId={config.googleClientId ?? ''}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Routes />
                <I18nInit />
              </LocalizationProvider>
            </GoogleOAuthProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </StoreContext.Provider>
    </MixpanelProvider>
  )
}