import React, { ReactNode, useEffect, useState } from 'react'
import {
  MaterialReactTable,
  // createRow,
  type MRT_ColumnDef,
  type MRT_Row,
  useMaterialReactTable,
} from 'material-react-table'
import { getOrg, isAdminRole } from '../../service/orgsApi'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { OrgUser, OrgUserRole, ProductName } from '../../client'
import ProfilePhoto from './ProfilePhoto'
import OrgUserEditDialog from './OrgUserEditDialog'
import { Typography } from '@mui/material'
import { SkeletonRows } from 'components/Skeletons'

interface CellProps {
  renderedCellValue: ReactNode
  row: MRT_Row<OrgUser>
}

export default function OrgUsers() {
  const queryClient = useQueryClient()

  const { data: org, isLoading } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })
  const isAdmin = isAdminRole(org)

  const OrgUserRoleOptions = [OrgUserRole.ADMIN, OrgUserRole.MEMBER, OrgUserRole.INACTIVE]
  const [openDialog, setOpenDialog] = useState(false)
  const [user, setUser] = useState<OrgUser>({})
  const columns: MRT_ColumnDef<OrgUser>[] = [
    {
      accessorKey: 'profileUrl',
      header: '',
      enableEditing: false,
      size: 50,
      Cell: CellImage,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      enableEditing: false,
      size: 150,
      Cell: CellText,
    },
    {
      accessorKey: 'email',
      header: 'Email',
      enableEditing: false,
      size: 150,
      Cell: CellText,
    },
    {
      accessorKey: 'org_user_role',
      header: 'Role',
      editVariant: 'select',
      editSelectOptions: OrgUserRoleOptions,
      size: 80,
      Cell: CellText,
    },
    {
      accessorKey: 'org_user_plan',
      header: 'Plan',
      editVariant: 'select',
      editSelectOptions: ({ row }) => getPlanOptions(row.original.org_user_plan),
      size: 80,
      Cell: CellText,
    },
  ]

  const table = useMaterialReactTable({
    columns,
    data: org?.org_user_list ?? [],
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (!isAdmin)
          return
        setUser(row.original)
        setOpenDialog(true)
      },
      sx: { cursor: 'pointer' },
    }),
    enableHiding: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    positionGlobalFilter: 'left',
    getRowId: (row) => row.uid ?? '',
    muiTableContainerProps: { sx: { minHeight: '500px' } },
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] },
      density: 'compact'
    },
  })

  return (
    <>
      {isLoading
        ? <SkeletonRows count={5} height={50} />
        : <MaterialReactTable table={table} />
      }

      {user.uid &&
        < OrgUserEditDialog
          open={openDialog}
          setOpenDialog={setOpenDialog}
          user={user}
          setUser={setUser}
        />
      }

    </>
  )
}

function getPlanOptions(initialPlan?: ProductName) {
  initialPlan = initialPlan ? initialPlan : ProductName.FREE_TRIAL
  if (initialPlan == ProductName.FREE_TRIAL)
    return [ProductName.STANDARD, ProductName.STARTER, ProductName.LITE, ProductName.FREE_TRIAL]
  return [ProductName.STANDARD, ProductName.STARTER, ProductName.LITE, ProductName.EXPIRED]
}

function CellText({ renderedCellValue, row }: CellProps) {
  const isInActive = row.original.org_user_role == OrgUserRole.INACTIVE
  return (
    <Typography sx={isInActive ? {
      textDecoration: 'line-through',
      color: 'gray',
    } : {}}>
      {renderedCellValue}
    </Typography>
  )
}

function CellImage({ renderedCellValue, row }: CellProps) {
  return (
    <ProfilePhoto
      photoUrl={row.original.photo_url}
      width={35}
    />
  )
}
