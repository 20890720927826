import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Skeleton, Typography } from '@mui/material'
import { Path } from '../../routes'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getSession, singleUserPaidAutoApplyLicense } from '../../service/payApi'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { updateOrgUser, getEnterpriseParent } from 'service/orgsApi'
import { OrgUser } from 'client'
import { logInfo } from 'log'

export default function PaySuccessScreen() {
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const queryParams = new URLSearchParams(location.search)
  const sessionId = queryParams.get('session_id') ?? ''
  const [busy, setBusy] = useState(false)

  const { data: session, isLoading: isLoadingSession } = useQuery({
    queryKey: ['getSession'], queryFn: () => getSession(sessionId),
  })

  const isMadePayment = session?.amount_subtotal // vs update credit card

  useEffect(() => {
    async function run() {
      setBusy(true)
      if (session?.metadata) {
        const targetUser: OrgUser = session?.metadata
        logInfo('Pay Success. OrgUser to Update', session?.metadata)
        const res = await updateOrgUser(targetUser)
        if (!(res instanceof Error)) {
          queryClient.invalidateQueries({ queryKey: ['getOrg'] })
        }
      } else {
        const res = await singleUserPaidAutoApplyLicense()
        res && queryClient.invalidateQueries({ queryKey: ['getOrg'] })
      }
      setBusy(false)
    }
    run()
  }, [session])

  const skeletonHeight = 30
  return (
    <Box sx={sxBox}>
      <CheckCircleIcon fontSize='large' color='success' />
      <Box sx={{ padding: '1vh' }} />

      <Typography variant='h2'>
        {isMadePayment ? 'Payment Successful' : 'Updated Payment'}
      </Typography>

      <Box sx={{ padding: '1vh' }} />

      <Card>
        {
          (isLoadingSession || busy) ? (
            <>
              <Skeleton variant="rectangular" width={500} height={skeletonHeight} />
              <Typography textAlign={'center'}>Post Processing</Typography>
              <Skeleton variant="rectangular" width={500} height={skeletonHeight} />
              <Skeleton variant="rectangular" width={500} height={skeletonHeight} />
            </>
          ) : (
            <>
              {/* <Typography>{`Plan: ${product?.name}`}</Typography> */}

              {
                session?.customer_details?.email && (
                  <Typography>
                    {`Billing Admin: ${session?.customer_details.email}`}
                  </Typography>
                )
              }

              <Typography>
                {`Receipt will be sent to the Billing Admin`}
              </Typography>
            </>
          )
        }
      </Card>

      <Button variant='contained' sx={{ marginTop: 1 }}
        onClick={() => navigate(Path.Organization)}
      >
        See Organization
      </Button>

    </Box >
  )
}

const sxBox = {
  display: "flex",
  flexDirection: 'column',
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",

}