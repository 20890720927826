import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/system'
import { SxProps, Card, CardHeader, Typography, Tooltip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getEnterprise, isEnterpriseAdmin } from 'service/enterpriseApi'
import { MaterialReactTable, MRT_ColumnDef, MRT_Row, useMaterialReactTable } from 'material-react-table'
import { getSessionStorage, SessionStorageEnum, setSessionStorage } from 'sessionStorage'
import { useNavigate } from 'react-router-dom'
import { SkeletonRows } from 'components/Skeletons'
import { EnterpriseOrg, EnterprisePlanType } from 'client'
import { Path } from 'routes'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

interface CellProps {
  renderedCellValue: ReactNode
  row: MRT_Row<EnterpriseOrg>
}

export default function EnterpriseOrgList() {
  const navigate = useNavigate()
  const [pagination, setPagination] = useState(getSessionStorage(SessionStorageEnum.pagination) ?? { pageIndex: 0, pageSize: 10 })

  const { data: ent, isLoading } = useQuery({
    queryKey: ['getEnterprise'], queryFn: getEnterprise,
  })
  const isEntAdmin = isEnterpriseAdmin(ent)

  function onClickRow(row: MRT_Row<EnterpriseOrg>) {
    if (isEntAdmin)
      navigate(`${Path.EnterpriseOrg}/${row.original.org_id}`)
  }

  const columns = useMemo<MRT_ColumnDef<EnterpriseOrg>[]>(
    () => {
      const col = [
        {
          accessorKey: 'org_name', // access nested data with dot notation
          header: 'Org Name',
          size: 120,
        },
        {
          accessorKey: 'domains',
          header: 'Domains',
          size: 120,
          Cell: CellDomains
        },
      ]

      if (ent?.plan_type == EnterprisePlanType.SEATS) {
        col.push({
          accessorKey: 'standard',
          header: 'Standard Seats Allocated',
          size: 120,
        })
        col.push({
          accessorKey: 'starter',
          header: 'Starter Seats Allocated',
          size: 120,
        })
      }
      return col
    },
    [ent],
  )

  const table = useMaterialReactTable({
    columns,
    data: ent?.enterprise_org_list ?? [],
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onClickRow(row),
      sx: { cursor: 'pointer' },
    }),
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    positionGlobalFilter: 'left',
    state: {
      pagination: pagination,
      showGlobalFilter: true, // Always show the global filter
    },
    enableHiding: false,
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: '#fdfdfd', // Set background color for the entire table
      },
    },
    initialState: {
      sorting: [{ id: 'last_event_datetime', desc: true }],
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize
      }
    },
    onPaginationChange: (updater) => {
      if (typeof updater !== "function") return
      const newPageInfo = updater(table.getState().pagination)
      setSessionStorage(SessionStorageEnum.pagination, newPageInfo)
      setPagination(newPageInfo)
    }
  })

  return (
    <Card sx={{ margin: 0 }}>
      <CardHeader title='Enterprise Organizations'
        action={
          <Tooltip title="Contact us to Organizations to your Enterprise Plan">
            <HelpOutlineOutlinedIcon fontSize='small' />
          </Tooltip>
        }
      />

      {!isEntAdmin &&
        <Typography>Requires Admin Role to edit organizations</Typography>
      }

      {isLoading
        ? <SkeletonRows count={3} height={70} />
        : <MaterialReactTable table={table} />
      }
    </Card>

  )
}

function CellDomains({ row }: CellProps) {
  const domains = row.original.domains ?? []
  return (
    domains.map((d: string) => <Typography key={d}>{d}</Typography>)
  )
}