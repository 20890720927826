import React, { useEffect, useState } from 'react'
import {
  Box,
  SxProps,
  Typography,
  TextField,
  Skeleton,
  Chip,
  Snackbar,
  Alert,
} from '@mui/material'
import { formatDateToMMMDDYYYY } from '../../utils'
import { EventBase, FirebaseUser } from '../../client'
import { gSx } from '../../styles/Theme'
import CheckIcon from '@mui/icons-material/Check'
import WarningIcon from '@mui/icons-material/Warning'
import { updateEventTitle } from '../../service/eventApi'
import ProfilePhoto from 'components/settings/ProfilePhoto'
import { getFirebaseUser } from 'service'
import { auth } from 'service/api'

interface Props {
  data?: EventBase
  disabled?: boolean
}

export default function EventTitle({ data, disabled }: Props) {
  const [owner, setOwner] = useState<FirebaseUser | undefined>()
  const startDateObj = new Date(data?.start_time ?? '0')
  const [title, setTitle] = useState(data?.title ?? '')
  const [editTitle, setEditTitle] = useState(data?.title ?? '')
  const [isEdit, setEdit] = useState(false)
  const [titleHoverStyle, setTitleHoverStyle] = useState({ cursor: 'default' })

  const [err, setErr] = useState<string | undefined>()
  const [isSnackSuccess, setSnackSuccess] = useState(false)

  const userUID = auth.currentUser?.uid
  const isEditor = () => userUID == data?.owner_id ||
      data?.permissions?.find((u) => u.uid === userUID)?.access == 'Editor'
  
  useEffect(() => {
    if (isEditor()) {
      setTitleHoverStyle({ cursor: 'pointer' })
    }
    if (data) {
      setTitle(data?.title ?? '')
      setEditTitle(data?.title ?? '')
      if(data.owner_id) {
        getFirebaseUser(data.owner_id)
          .then((res) => {
            setOwner(res)
          })
      }
    }
  }, [data])

  async function handleSave() {
    if (editTitle === '') {
      setErr('Title cannot be empty')
      setEditTitle(title)
      setEdit(false)
      return
    }
    if (editTitle.length > 128) {
      setErr('Title cannot be more than 128 characters')
      setEditTitle(title)
      setEdit(false)
      return
    }
    setErr(undefined)
    if (data) {
      setEdit(false)
      const res = await updateEventTitle(data.id, editTitle.trim())
      if (res instanceof Error) {
        setErr(res.message)
        setEditTitle(title)
      } else {
        setTitle(res)
        setEditTitle(res)
        setSnackSuccess(true)
      }
    }
  }

  function handleCancel() {
    setEdit(false)
    setEditTitle(title)
  }

  return (
    <Box sx={{ width: '100%', paddingBottom: 2 }}>
      {isEdit ? (
        <Box sx={gSx.RowBetween}>
          <TextField
            value={editTitle}
            onChange={(t) => {
              setEditTitle(t.target.value)
            }}
            InputProps={{
              sx: {
                padding: 0,
                height: 'auto',
                fontSize: (theme) => theme.typography.h3.fontSize,
                fontWeight: (theme) => theme.typography.h3.fontWeight,
                lineHeight: (theme) => theme.typography.h3.lineHeight,
                fontFamily: (theme) => theme.typography.h3.fontFamily,
              },
            }}
            sx={{
              ...sxTextField,
              // Remove all padding from the input
              '& .MuiOutlinedInput-root': {
                padding: 0,
                height: 'auto',
                '& input': {
                  padding: '0px 1px',
                },
              },
            }}
            onBlur={title != editTitle ? handleSave : handleCancel}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSave()
              } else if (e.key === 'Escape') {
                handleCancel()
              }
            }}
            autoFocus
          />
        </Box>
      ) : (
        <Box sx={gSx.RowBetween}>
          <Box sx={gSx.Row}>
            {!data || !data.owner_id ? (
              <Skeleton variant='rectangular' width={350} height={40} />
            ) : (
              <Typography
                variant='h3'
                onClick={() => isEditor() && setEdit(true)}
                sx={{
                  ...sxTextField,
                  ...titleHoverStyle,
                  // Padding to match input
                  padding: '4px 1px 2.5px 1px',
                }}
              >
                {title}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {<Snackbar
        open={err !== undefined}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        message = {err}
        onClose={() => setErr(undefined)}
        ClickAwayListenerProps={{ onClickAway: () => null }}
      >      
        <Alert
          variant='filled'
          icon={<WarningIcon fontSize='inherit' />}
          onClose={() => setErr(undefined)}
          severity='error'
          sx={{ width: '100%' }}
        >
          {err}
        </Alert>  
      </Snackbar>}

      {!data ? (
        <Skeleton variant='rectangular' width={100} height={20} />
      ) : (
        <Box sx={gSx.Row}>
          <Chip
            avatar={<ProfilePhoto photoUrl={owner?.photo_url} width={25} />}
            label={owner?.display_name}
            variant='outlined'
            sx={{ paddingX: 0.5 }}
          />
          <Box sx={{ padding: 1 }} />
          <Typography sx={sxDate}>
            {`${formatDateToMMMDDYYYY(startDateObj)}, ${startDateObj.toLocaleTimeString()}`}
          </Typography>
        </Box>
      )}

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant='filled'
          icon={<CheckIcon fontSize='inherit' />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Title Updated Succesfully
        </Alert>
      </Snackbar>
    </Box>
  )
}

const sxTextField: SxProps = {
  width: '100%',
  marginRight: '3vh',
}

const sxDate: SxProps = {}
