import { Box, Button, Card, CardHeader, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getEnterprise } from "service/enterpriseApi"

export default function EnterpriseGeneralCard() {
  const queryClient = useQueryClient()

  const { data: ent } = useQuery({
    queryKey: ['getEnterprise'], queryFn: getEnterprise,
    enabled: queryClient.getQueryData(['getEnterprise']) === undefined,
  })

  return (
    <Card sx={{ flexGrow: 1, margin: 0 }}>
      <CardHeader title='General' />

      <Grid container columnSpacing={2}>
        <Grid xs={5}>
          <Typography variant='h6'>Enterprise Name</Typography>
        </Grid>
        <Grid xs={7}>
          <Typography>{ent?.name}</Typography>
        </Grid>

        <Grid xs={5}>
          <Typography variant='h6'>Plan Type</Typography>
        </Grid>
        <Grid xs={7}>
          <Typography>{ent?.plan_type}</Typography>
        </Grid>
      </Grid>
    </Card>
  )
}
