/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SystemBase } from '../models/SystemBase';
import type { WebBanner } from '../models/WebBanner';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SystemsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get System
     * @returns SystemBase Successful Response
     * @throws ApiError
     */
    public getSystem(): CancelablePromise<SystemBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/systems/',
        });
    }

    /**
     * Get Web Banner
     * @returns WebBanner Successful Response
     * @throws ApiError
     */
    public getWebBanner(): CancelablePromise<WebBanner> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/systems/banners/web',
        });
    }

}
