import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Dialog, DialogTitle, IconButton, Menu, MenuItem, Snackbar, SxProps, Typography, Divider, DialogActions, DialogContent } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { deleteEvent } from 'service/eventApi'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQueryClient, useQuery } from '@tanstack/react-query'
import CheckIcon from '@mui/icons-material/Check'
import { sleep } from 'utils'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from '../../hooks/hookHelpers'
import { getEventSummaryStatus } from '../../service/eventApi'
import { ProcessingStatus } from '../../client'
import { SummaryRerunIcon, EmailRerunIcon } from '../CustomIcons'
import { RerunSummaryConfirmDialog, RerunSummaryDialog } from './rerun/RerunSummaryDialog'
import { RerunFollowupEmailConfirmDialog, RerunFollowupEmailDialog } from './rerun/RerunFollowupEmailDialog'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { ExportPdfDialog } from './export/ExportPdfDialog'

interface Props {
  eventId: string
  isOwner?: boolean
  isEditor?: boolean
}

export default function EventMenu({ eventId, isOwner, isEditor }: Props) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const queryClient = useQueryClient()

  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openExportPdfDialog, setOpenExportPdfDialog] = useState(false)
  const [openRerunSummaryDialog, setOpenRerunSummaryDialog] = useState(false)
  const [openRerunFollowupEmailDialog, setOpenRerunFollowupEmailDialog] = useState(false)
  const [openRerunSummaryConfirmDialog, setOpenRerunSummaryConfirmDialog] = useState(false)
  const [openRerunFollowupEmailConfirmDialog, setOpenRerunFollowupEmailConfirmDialog] = useState(false)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const disabled = !(isOwner || isEditor)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const currentUrl = window.location.href
  const isPublicEvent = currentUrl.includes('public')

  const { data: summaryStatus } = useQuery({
    queryKey: ['getEventSummaryStatus', eventId],
    queryFn: runQueryEventSummaryStatus,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
    refetchInterval: () => 7000, // refetch every 7 sec when summary is not loaded
  })

  async function runQueryEventSummaryStatus() {
    if (eventId) {
      const res = await getEventSummaryStatus(eventId)
      return res
    }
  }

  function onMenuDeleteOpen() {
    setOpenDeleteDialog(true)
    setAnchorEl(null)
  }

  async function onMenuExportPdfOpen() {
    setOpenExportPdfDialog(true)
    setAnchorEl(null)
  }

  async function onMenuRerunSummaryOpen() {
    setOpenRerunSummaryConfirmDialog(true)
    setAnchorEl(null)
  }

  async function onRerunSummaryOpen() {
    setOpenRerunSummaryDialog(true)
    setOpenRerunSummaryConfirmDialog(false)
    setAnchorEl(null)
  }

  async function onMenuRerunFollowupEmailOpen() {
    setOpenRerunFollowupEmailConfirmDialog(true)
    setAnchorEl(null)
  }

  async function onRerunFollowupEmailOpen() {
    setOpenRerunFollowupEmailDialog(true)
    setOpenRerunFollowupEmailConfirmDialog(false)
    setAnchorEl(null)
  }

  async function onDeleteEvent() {
    setBusy(true)
    setErr(undefined)
    const res = await deleteEvent(eventId)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setOpenDeleteDialog(false)
      setSnackSuccess(true)
      await queryClient.invalidateQueries({ queryKey: ['getEvents'] })
      await sleep(2000)
      navigate(Path.Home)
    }
    setBusy(false)
  }

  function closeAllMenu() {
    setAnchorEl(null)
    setOpenExportPdfDialog(false)
    setOpenRerunSummaryDialog(false)
    setOpenRerunFollowupEmailDialog(false)
    setOpenDeleteDialog(false)
    queryClient.invalidateQueries({ queryKey: ['getEventSummaryStatus', eventId] })
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        {
          summaryStatus?.summary_overall_status == ProcessingStatus.DONE &&
          <MenuItem onClick={onMenuExportPdfOpen}>
            <PictureAsPdfIcon sx={{ color: 'gray', marginRight: "15px" }} />Export PDF
          </MenuItem>
        }
        {
          (summaryStatus?.followup_email_status == ProcessingStatus.DONE || summaryStatus?.summary_overall_status == ProcessingStatus.DONE) &&
          <Divider />
        }
        {
          summaryStatus?.summary_overall_status == ProcessingStatus.DONE &&
          <MenuItem onClick={onMenuRerunSummaryOpen}>
            <SummaryRerunIcon />Rerun Meeting Summary
          </MenuItem>
        }
        {
          summaryStatus?.followup_email_status == ProcessingStatus.DONE &&
          <MenuItem onClick={onMenuRerunFollowupEmailOpen}>
            <EmailRerunIcon /> Rerun Followup Email
          </MenuItem>

        }
        {
          (summaryStatus?.followup_email_status == ProcessingStatus.DONE || summaryStatus?.summary_overall_status == ProcessingStatus.DONE) &&
          <Divider />
        }
        <MenuItem onClick={onMenuDeleteOpen} disabled={!isOwner}>
          <DeleteForeverIcon sx={{ color: 'gray', marginRight: "15px" }} />
          Delete Meeting
        </MenuItem>
      </Menu >

      <RerunSummaryConfirmDialog open={openRerunSummaryConfirmDialog} onCancel={() => setOpenRerunSummaryConfirmDialog(false)} onRerunSummaryOpen={() => onRerunSummaryOpen()} />
      <RerunSummaryDialog eventId={eventId} open={openRerunSummaryDialog} onCancel={() => setOpenRerunSummaryDialog(false)} onClose={closeAllMenu} />
      <RerunFollowupEmailConfirmDialog open={openRerunFollowupEmailConfirmDialog} onCancel={() => setOpenRerunFollowupEmailConfirmDialog(false)} onRerunFollowupEmailOpen={() => onRerunFollowupEmailOpen()} />
      <RerunFollowupEmailDialog eventId={eventId} open={openRerunFollowupEmailDialog} onCancel={() => setOpenRerunFollowupEmailDialog(false)} onClose={closeAllMenu} />
      {openExportPdfDialog && <ExportPdfDialog eventId={eventId} open={openExportPdfDialog} onCancel={() => setOpenExportPdfDialog(false)} />}

      {/* Delete Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete Meeting</DialogTitle>

        <DialogContent>
          <Typography>By Deleting Meetings, Pre-meeting Note preparations will be affected</Typography>

          <Typography color='tomato'>Warning: This Action is Irreversible</Typography>
        </DialogContent>

        <DialogActions>

          <Button
            onClick={() => setOpenDeleteDialog(false)}
            color="primary"
            aria-label='Keep'
            sx={{ marginX: '10px' }}
          >
            Keep
          </Button>

          <LoadingButton
            sx={{ marginX: '10px' }}
            onClick={onDeleteEvent}
            color="error"
            loading={busy}
            autoFocus
            aria-label='Delete'
            disabled={isPublicEvent}
            variant='contained'
          >
            Delete
          </LoadingButton>

        </DialogActions>

        {err &&
          <Typography color={'red'} sx={{ padding: 2 }}>
            {err}
          </Typography>
        }
      </Dialog >

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Meeting Deleted
        </Alert>
      </Snackbar>
    </>
  )
}
