import { PreciseFpConfigBase, ClientData } from "client"
import { logErr, logInfo } from "log"
import { getErrorMessage } from "utils"
import { Api, auth, config } from "service/api"
import queryString from 'query-string'

export async function precisefpOAuth() {
  const windowName = 'PopupWindow'
  const windowFeatures = 'width=500,height=700'

  const queryParams = {
    client_id: config.preciseFpClientId,
    redirect_uri: config.preciseFpRedirect,
    response_type: "code",
    scope: "*",
  }
  console.log("precisefpOAuth", queryParams)
  try {
    const baseUrl = `https://app.precisefp.com/oauth/authorize`
    const url = `${baseUrl}?${queryString.stringify(queryParams)}`
    window.open(url, windowName, windowFeatures)
  } catch (e) {
    logErr('precisefpOAuth sign in')
  }
}

export async function disconnectPrecisefp(): Promise<undefined | Error> {
  try {
    const api = await Api()
    await api.precisefp.disconnectPreciseFp()
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('precisefp disconnect', { err })
    return err
  }
}

export async function getPreciseFpConfig(): Promise<PreciseFpConfigBase | undefined> {
  if (!auth.currentUser)
    return
  try {
    const api = await Api()
    const res = await api.precisefp.getPreciseFpConfig()
    return res
  } catch (e) {
    logErr('getPreciseFpConfig', { e })
    throw e
  }
}

export async function getPreciseFpDataset(): Promise<any | undefined> {
  if (!auth.currentUser)
    return
  try {
    const api = await Api()
    const res = await api.precisefp.getDataset()
    if (!res["by_title"] || !res["dynamic_ids"])
      throw new Error("PFP dataset: no required fields")
    return res
  } catch (e) {
    logErr('getPreciseFpDataset', { e })
    throw e
  }
}

export async function getPreciseFpClientData(eventId: string): Promise<ClientData | undefined> {
  if (!auth.currentUser)
    return
  try {
    const api = await Api()
    const res = await api.precisefp.getClientData(eventId)
    return res
  } catch (e) {
    logErr('getPreciseFpClientData', { e })
    throw e
  }
}

export async function authPreciseFp(code: string): Promise<PreciseFpConfigBase> {
  try {
    const api = await Api()
    const res = await api.precisefp.authPreciseFp(code)
    return res
  } catch (e) {
    logErr('authPreciseFp', { e })
    throw e
  }
}
