import React, { useEffect, useState } from "react"
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, Dialog, Box, DialogActions, DialogTitle, IconButton, DialogContent, Skeleton, Snackbar, Alert } from "@mui/material"
import { addToSubscription, calculateDiscountAmount, createCheckoutSession, getCustomer, getSubscription } from "../../service/payApi"
import { OrgBase, ProductName } from "client"
import { isAdminRole, numActiveOrgUsersByPlan } from "service/orgsApi"
import { LoadingButton } from "@mui/lab"
import { logInfo, logWarn } from "log"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { gColor, gSx } from "styles/Theme"
import Discount from "./Discount"
import CheckIcon from '@mui/icons-material/Check'
import { getUser } from "service"

interface Props {
  org: OrgBase // cannot be optional. else seats dont initialize correctly
  open: boolean
  setOpen: (open: boolean) => void
}

export default function CheckoutDialog({ org, open, setOpen }: Props) {
  const queryClient = useQueryClient()

  const { data: user } = useQuery({
    queryKey: ['getUser'], queryFn: async () => await getUser(),
    enabled: queryClient.getQueryData(['getUser']) === undefined,
  })
  const clientReferenceId = user?.affiliate_cid

  const isAdmin = isAdminRole(org)
  const standardOrg = numActiveOrgUsersByPlan(ProductName.STANDARD, org.org_user_list)
  const starterOrg = numActiveOrgUsersByPlan(ProductName.STARTER, org.org_user_list)
  const liteOrg = numActiveOrgUsersByPlan(ProductName.LITE, org.org_user_list)
  const maxLiteSeats = org.plan_max_lite_seats ?? 2

  const { data: customer, isLoading: isLoadingCust } = useQuery({
    queryKey: ['getCustomer'], queryFn: getCustomer
  })

  const { data: sub, isLoading: isLoadingSub, refetch } = useQuery({
    queryKey: ['getSubscription'], queryFn: getSubscription
  })

  const isSubscribed = !!sub?.subscription
  const isLoading = isLoadingCust || isLoadingSub

  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [numStandardPay, setNumStandardPay] = useState(0)
  const [numStarterPay, setNumStarterPay] = useState(0)
  const [numLitePay, setNumLitePay] = useState(0)

  const standardPrice = (sub?.standard?.price?.unit_amount ?? 15000) / 100
  const starterPrice = (sub?.starter?.price?.unit_amount ?? 95000) / 100
  const litePrice = (sub?.lite?.price?.unit_amount ?? 95000) / 100
  const seatTotalPrice = (standardPrice * numStandardPay) + (starterPrice * numStarterPay)
  const discount = calculateDiscountAmount(seatTotalPrice, customer?.discount)
  const availableLiteSeats = maxLiteSeats - (sub?.lite?.quantity ?? 999)

  useEffect(() => {
    if (open)
      logInfo('Checkout Dialog', { standardOrg, starterOrg, liteOrg })
  }, [open])

  useEffect(() => {
    if (numStandardPay < 0)
      setNumStandardPay(0)
  }, [numStandardPay])

  useEffect(() => {
    if (numStarterPay < 0)
      setNumStarterPay(0)
  }, [numStarterPay])

  useEffect(() => {
    if (numLitePay < 0)
      setNumLitePay(0)
    else if (numLitePay > availableLiteSeats)
      setNumLitePay(availableLiteSeats)
  }, [numLitePay])

  async function onSubscribe() {
    logInfo('Checkout Dialog, Pressed Subscribed', { standardOrg, starterOrg, numStandardPay, numStarterPay })
    setBusy(true)
    setErr(undefined)

    if (isSubscribed) {
      await refetch()
      if (numLitePay > availableLiteSeats) {
        logWarn("Lite seats maximum reached")
        setErr("Lite seats maximum reached")
        setBusy(false)
        return
      }
      const res = await addToSubscription(numStandardPay, numStarterPay, numLitePay)
      if (res instanceof Error) {
        setErr(res.message)
        setBusy(false)
        return
      } else {
        setSnackSuccess(true)
        await refetch()
      }
    } else {
      if (numStandardPay < standardOrg) {
        setErr(`You need at least ${standardOrg} standard seats`)
        setBusy(false)
        return
      }
      if (numStarterPay < starterOrg) {
        setErr(`You need at least ${starterOrg} starter seats`)
        setBusy(false)
        return
      }

      const res = await createCheckoutSession(numStandardPay, numStarterPay, numLitePay)
      if (res instanceof Error) {
        setErr(res.message)
        setBusy(false)
        return
      } else
        window.location.href = res
    }

    setBusy(false)
    setOpen(false)
  }

  function onClose(event: object, reason: string) {
    if (busy) // && (reason == "escapeKeyDown" || reason == "backdropClick"))
      return
    logInfo('Edit User Close')
    setOpen(false)
  }

  if (!isAdmin)
    return null

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={'sm'}
      >

        {
          isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={300} />
            : (
              <>
                <DialogTitle>
                  {isSubscribed ? "Purchase Additional Seats" : "Start Subscription"}
                </DialogTitle>

                <DialogContent sx={{ display: 'flex', paddingX: 10, flexDirection: 'column' }}>

                  <TableContainer >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Plan</TableCell>
                          <TableCell>Price/unit</TableCell>
                          <TableCell align='right'>Number of Seats</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        <TableRow >
                          <TableCell component="th" scope="row">Standard</TableCell>

                          <TableCell>${standardPrice}</TableCell>
                          <TableCell align={'right'}>
                            <TextField
                              id='standard'
                              aria-label='standard'
                              label='Standard Seats'
                              variant='outlined'
                              sx={{ width: 120 }}
                              disabled={busy}
                              value={numStandardPay}
                              type="number"
                              onChange={(t) => setNumStandardPay(Number(t.target.value))}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow >
                          <TableCell component="th" scope="row">Starter</TableCell>

                          <TableCell>${starterPrice}</TableCell>
                          <TableCell align={'right'}>
                            <TextField
                              sx={{ width: 120 }}
                              id='starter'
                              aria-label='starter'
                              label='Starter Seats'
                              variant='outlined'
                              disabled={busy}
                              value={numStarterPay}
                              type="number"
                              onChange={(t) => setNumStarterPay(Number(t.target.value))}
                            />
                          </TableCell>
                        </TableRow>

                        {availableLiteSeats > 0 &&
                          <TableRow >
                            <TableCell component="th" scope="row">Lite</TableCell>

                            <TableCell>${litePrice}</TableCell>
                            <TableCell align={'right'}>
                              <TextField
                                sx={{ width: 120 }}
                                id='lite'
                                aria-label='lite'
                                label='Lite Seats'
                                variant='outlined'
                                disabled={busy}
                                value={numLitePay}
                                type="number"
                                onChange={(t) => setNumLitePay(Number(t.target.value))}
                              />
                            </TableCell>
                          </TableRow>
                        }

                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Typography variant="caption">{`Lite Seat purchase limit ${maxLiteSeats}. Contact sales for additional Lite Seats.`}</Typography>

                  {customer?.discount &&
                    <Typography variant="caption">Price is prior to discount. Discount will be applied on checkout.</Typography>
                  }
                  <Discount />
                  <Typography sx={{ color: 'tomato' }}>{err}</Typography>

                  <Box sx={gSx.RowBetween}>
                    <Typography>Seats per month</Typography>
                    <Typography>${seatTotalPrice}</Typography>
                  </Box>

                  {
                    customer?.discount &&
                    <Box sx={gSx.RowBetween}>
                      <Typography sx={{ color: gColor.green500 }}>Discount</Typography>
                      <Typography sx={{ color: gColor.green500 }}>-${discount.toFixed(2)}</Typography>
                    </Box>
                  }

                  <Box sx={{ padding: 2 }} />

                  <Typography variant='caption'>
                    After purchasing license(s), please apply the license to the relevant user account(s) on the Organization Page
                  </Typography>
                </DialogContent >

                <DialogActions>
                  <LoadingButton
                    onClick={() => onClose({}, '')}
                    aria-label={'cancel'}
                  >
                    Cancel
                  </LoadingButton>

                  <LoadingButton
                    variant={'contained'}
                    onClick={async () => { onSubscribe() }}
                    aria-label={'Subscribe'}
                    disabled={busy || (numStandardPay + numStarterPay + numLitePay == 0 || isLoading)}
                    loading={busy}
                    sx={{ paddingX: 5 }}
                  >
                    Checkout
                  </LoadingButton>

                </DialogActions>
              </>
            )
        }

      </Dialog >

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Purchase complete
        </Alert>
      </Snackbar>
    </>
  )
}

export function NonAdminMessageToSubscribe() {
  return (
    <Typography textAlign={'center'} color={'primary'}>
      Contact your Administrator to upgrade
    </Typography>
  )
}
