/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { CalendarService } from './services/CalendarService';
import { ClientsService } from './services/ClientsService';
import { CommunicationsService } from './services/CommunicationsService';
import { CustomizationsService } from './services/CustomizationsService';
import { DefaultService } from './services/DefaultService';
import { EnterpriseService } from './services/EnterpriseService';
import { EventsService } from './services/EventsService';
import { FidelitycaService } from './services/FidelitycaService';
import { FrontendService } from './services/FrontendService';
import { OrgsService } from './services/OrgsService';
import { PayService } from './services/PayService';
import { PrecisefpService } from './services/PrecisefpService';
import { PublicService } from './services/PublicService';
import { RedtailService } from './services/RedtailService';
import { RingcentralService } from './services/RingcentralService';
import { SalesforceService } from './services/SalesforceService';
import { SystemsService } from './services/SystemsService';
import { UsersService } from './services/UsersService';
import { WealthboxService } from './services/WealthboxService';
import { WebhooksService } from './services/WebhooksService';
import { ZoomService } from './services/ZoomService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class AppClient {

    public readonly calendar: CalendarService;
    public readonly clients: ClientsService;
    public readonly communications: CommunicationsService;
    public readonly customizations: CustomizationsService;
    public readonly default: DefaultService;
    public readonly enterprise: EnterpriseService;
    public readonly events: EventsService;
    public readonly fidelityca: FidelitycaService;
    public readonly frontend: FrontendService;
    public readonly orgs: OrgsService;
    public readonly pay: PayService;
    public readonly precisefp: PrecisefpService;
    public readonly public: PublicService;
    public readonly redtail: RedtailService;
    public readonly ringcentral: RingcentralService;
    public readonly salesforce: SalesforceService;
    public readonly systems: SystemsService;
    public readonly users: UsersService;
    public readonly wealthbox: WealthboxService;
    public readonly webhooks: WebhooksService;
    public readonly zoom: ZoomService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.calendar = new CalendarService(this.request);
        this.clients = new ClientsService(this.request);
        this.communications = new CommunicationsService(this.request);
        this.customizations = new CustomizationsService(this.request);
        this.default = new DefaultService(this.request);
        this.enterprise = new EnterpriseService(this.request);
        this.events = new EventsService(this.request);
        this.fidelityca = new FidelitycaService(this.request);
        this.frontend = new FrontendService(this.request);
        this.orgs = new OrgsService(this.request);
        this.pay = new PayService(this.request);
        this.precisefp = new PrecisefpService(this.request);
        this.public = new PublicService(this.request);
        this.redtail = new RedtailService(this.request);
        this.ringcentral = new RingcentralService(this.request);
        this.salesforce = new SalesforceService(this.request);
        this.systems = new SystemsService(this.request);
        this.users = new UsersService(this.request);
        this.wealthbox = new WealthboxService(this.request);
        this.webhooks = new WebhooksService(this.request);
        this.zoom = new ZoomService(this.request);
    }
}

