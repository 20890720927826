import React, { useState } from 'react'
import { Grid, Typography, FormControl, Select, MenuItem, Skeleton, Tooltip, LinearProgress, Box } from '@mui/material'
import { getOrg, getOrgConfigs, getOrgUser, updateOrgConfigs } from 'service/orgsApi'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { OrgConfigs, OrgUserRole } from 'client'
import { auth } from 'service/api'

const summary_options = [
  { value: 14, label: '2 weeks' },
  { value: 30, label: '1 month' },
  { value: 90, label: '3 months' },
  { value: 180, label: '6 months' },
  { value: 365, label: '1 year' },
  { value: 730, label: '2 years' },
]

const transcript_options = [
  { value: 0, label: 'No Retention' },
  { value: 14, label: '2 weeks' },
  { value: 30, label: '1 month' },
  { value: 90, label: '3 months' },
  { value: 180, label: '6 months' },
  { value: 365, label: '1 year' },
  { value: 730, label: '2 years' },
]

const video_options = [
  { value: 0, label: 'No Retention' },
  { value: 14, label: '2 weeks' },
  { value: 30, label: '1 month' },
]

const OrgDataRetention = () => {

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg
  })
  const fbUser = auth.currentUser
  const orgUser = getOrgUser(org, fbUser?.uid)
  const [busy, setBusy] = useState(false)

  const queryClient = useQueryClient()
  const selectReadOnly = orgUser?.org_user_role != OrgUserRole.ADMIN

  // TODO: only allow admin to change
  const { data, isLoading } = useQuery({
    queryKey: ['getOrgConfigs'],
    queryFn: async () => await getOrgConfigs(),
  })

  const mutation = useMutation({
    mutationFn: async (newOrgConfigs: OrgConfigs) => {
      setBusy(true)
      await updateOrgConfigs(newOrgConfigs)
      setBusy(false)
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['getOrgConfigs'] })
    },
  })

  const handleSummaryRetentionChange = (updatedChange: any) => {
    const newOrgConfigs = {
      data_retention_days_summary: updatedChange.target.value,
    } as OrgConfigs
    mutation.mutate(newOrgConfigs)
  }

  const handleTranscriptRetentionChange = (updatedChange: any) => {
    const newOrgConfigs = {
      data_retention_days_transcript: updatedChange.target.value,
    } as OrgConfigs
    mutation.mutate(newOrgConfigs)
  }

  const handleVideoRetentionChange = (updatedChange: any) => {
    const newOrgConfigs = {
      data_retention_days_video: updatedChange.target.value,
    } as OrgConfigs
    mutation.mutate(newOrgConfigs)
  }
  const skeletonHeight = 50
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Typography variant="subtitle1">Retain summary and followup email for:</Typography>
        </Grid>
        <Grid item xs={6}>
          {
            isLoading && <>
              <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
            </>
          }{
            !isLoading &&
            <Tooltip title={selectReadOnly ? "Adjustable only by Admin" : ""}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  labelId="retention-select-label"
                  id="retention-select"
                  value={data.data_retention_days_summary}
                  onChange={handleSummaryRetentionChange}
                  inputProps={{ readOnly: selectReadOnly }}
                >
                  {summary_options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>

          }
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Retain transcript for:</Typography>
        </Grid>
        <Grid item xs={6}>
          {
            isLoading && <>
              <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
            </>
          }{
            !isLoading &&
            <Tooltip title={selectReadOnly ? "Adjustable only by Admin" : ""}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  labelId="retention-select-label"
                  id="retention-select"
                  value={data.data_retention_days_transcript}
                  onChange={handleTranscriptRetentionChange}
                  inputProps={{ readOnly: selectReadOnly }}
                >
                  {transcript_options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>

          }
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Retain video for:</Typography>
        </Grid>
        <Grid item xs={6}>
          {
            isLoading && <>
              <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
            </>
          }{
            !isLoading &&
            <Tooltip title={selectReadOnly ? "Adjustable only by Admin" : "For longer retention, contact support"}>
              <FormControl variant="outlined" fullWidth>
                <Select
                  labelId="video-retention-select-label"
                  id="video-retention-select"
                  value={data.data_retention_days_video}
                  onChange={handleVideoRetentionChange}
                  inputProps={{ readOnly: selectReadOnly }}
                >
                  {video_options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          }
        </Grid>

      </Grid >
      {
        busy && <Box sx={{ width: '100%', paddingY: '1vh' }}><LinearProgress /></Box>
      }
    </>
  )
}

export default OrgDataRetention