import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, Typography, DialogActions, DialogTitle, DialogContent, Chip, Card, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, IconButton, Snackbar, Alert } from '@mui/material'
import { logInfo } from '../../log'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { auth, config } from 'service/api'
import { OrgBase, OrgUserRole } from 'client'
import { isEmailValid, isValidDomain, listValidDomains } from 'utils'
import { createOrgInvites, isAdminRole } from 'service/orgsApi'
import { LoadingButton } from '@mui/lab'
import { gColor, gSx } from 'styles/Theme'
import { useQueryClient } from '@tanstack/react-query'
import CheckIcon from '@mui/icons-material/Check'

interface Props {
  org: OrgBase
}
export default function OrgInviteCreate({ org }: Props) {
  const queryClient = useQueryClient()
  const fbUser = auth.currentUser
  const isIndividual = org.is_individual
  const MAX_NUM_EMAILS = 10
  const isAdmin = isAdminRole(org)

  const [openDialog, setOpenDialog] = useState(false)
  const [role, setRole] = useState<OrgUserRole>(OrgUserRole.MEMBER)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [isSnackSuccess, setSnackSuccess] = useState(false)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
      event.preventDefault()
      addEmail(inputValue)
    } else if (event.key === 'Backspace' && inputValue.length == 0) {
      setEmails(emails.slice(0, -1))
    }
  }

  const addEmail = (inputEmail: string) => {
    setErr('')
    const email = inputEmail.trim()
    const members = org.org_user_list.map(m => m.email)
    if (!isEmailValid(email) || !fbUser?.email) {
      return ''
    } else if (members.includes(email)) {
      setErr(`${email} already a member`)
    } else if (emails.length > MAX_NUM_EMAILS) {
      setErr(`Max of ${MAX_NUM_EMAILS} emails allowed at a time`)
    } else if (!isValidDomain(email, org)) {
      setErr(`Blocked: Email belongs to an external domain. Contact support to add domains.`)
    } else if (email && !emails.includes(email)) { // no duplicates
      setEmails([...emails, email])
      setInputValue('')
      return email
    }
    return ''
  }

  const removeEmail = (emailToRemove: string) => {
    setEmails(emails.filter((email) => email !== emailToRemove))
  }

  async function onRole(event: SelectChangeEvent) {
    setRole(event.target.value as OrgUserRole)
  }

  useEffect(() => {
    if (openDialog) {
      logInfo('OrgInviteCreate Open')
      setEmails([])
    }
  }, [openDialog])

  async function onInvite() {
    setBusy(true)
    setErr('')
    const lastEmail = addEmail(inputValue)
    const sentInvites = await createOrgInvites(role, [...emails, lastEmail])
    const sentEmails = sentInvites?.map(invite => invite.recipient_email)
    if (sentEmails && sentEmails.length > 0) {
      queryClient.invalidateQueries({ queryKey: ['getOrgInvites'] })
      setSnackSuccess(true)
      setOpenDialog(false)
    }
    setBusy(false)
  }

  if (!isAdmin || isIndividual)
    return null

  return (
    <>
      <Button
        startIcon={<PersonAddIcon />}
        variant='outlined'
        onClick={() => setOpenDialog(true)}
      >
        Invite
      </Button>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          Invite to your Organization
        </DialogTitle>

        <DialogContent>
          <Typography>
            {`Add only internal emails (${listValidDomains(org)})`}
          </Typography>

          <Card sx={{ minWidth: 500, marginBottom: 0, padding: 1 }}>
            {emails.map((email, index) => (
              <Chip
                key={index}
                label={email}
                onDelete={() => removeEmail(email)}
                disabled={busy}
              />
            ))}

            <input
              autoFocus
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              placeholder="Enter email(s)..."
              style={{ border: 'none', outline: 'none', width: '300px', height: '30px' }}
              disabled={busy}
            />
          </Card>

          <Typography variant='caption'>
            For multiple emails, press enter, comma, or space
          </Typography>

          <Box sx={{ padding: 2 }} />

          <FormControl fullWidth>
            <InputLabel id="role">Role</InputLabel>
            <Select
              labelId="role"
              id="role-select"
              value={role}
              label="Role"
              onChange={onRole}
            >
              <MenuItem value={OrgUserRole.MEMBER}>Member</MenuItem>
            </Select>
          </FormControl>
          <Typography variant='caption'>Roles can be changed once they join.</Typography>

          {err && <Typography color={'tomato'} sx={{ paddingTop: 1 }}>{err}</Typography>}
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            loading={busy}
            variant="contained"
            onClick={onInvite}
            color="primary"
            startIcon={<PersonAddIcon />}
            disabled={busy || (inputValue.length == 0 && emails.length == 0)}
          >
            Send Invites
          </LoadingButton>
        </DialogActions>

      </Dialog >
      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          {emails.length == 1 &&
            <Typography>Invite Sent to {emails[0]}</Typography>
          }
          {emails.length > 1 &&
            <Typography>Invites Sent to {emails.join(', ')}</Typography>
          }

        </Alert>
      </Snackbar>
    </>
  )
}
