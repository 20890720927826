import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Box, Button, Card, CardHeader, IconButton, Skeleton, SxProps, TextField, Typography } from '@mui/material'
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { OrgUserRole } from '../../client'
import { getOrg, getOrgUser, setOrgName } from '../../service/orgsApi'
import { auth } from 'service/api'
import { getEnterpriseParent } from 'service/orgsApi'

const OrgName = () => {
  const fbUser = auth.currentUser
  const queryClient = useQueryClient()
  const [editName, setEditName] = useState('')
  const [isEdit, setEdit] = useState(false)

  const { data: org, isLoading } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
  })
  const orgUser = getOrgUser(org, fbUser?.uid)
  const isAdmin = orgUser?.org_user_role == OrgUserRole.ADMIN

  const { data: ent } = useQuery({
    queryKey: ['getEnterpriseParent'], queryFn: getEnterpriseParent,
    enabled: queryClient.getQueryData(['getEnterpriseParent']) === undefined,
  })

  const mutation = useMutation({
    mutationFn: async (newOrgName: string) => {
      await setOrgName(newOrgName)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getOrg'] })
    },
  })

  async function handleSave() {
    mutation.mutate(editName)
    setEdit(false)
  }

  async function handleCancel() {
    setEdit(false)
  }

  function startEdit() {
    if (org)
      setEditName(org.org_name)
    setEdit(true)
  }

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader title="General" />

      {
        ent?.name &&
        <Grid container alignItems="center" sx={{ width: '100%' }}>
          <Grid xs={5}>
            <Typography variant='h6'>Enterprise Affiliation</Typography>
          </Grid>
          <Grid xs={7}>
            <Typography>{ent?.name}</Typography>
          </Grid>
        </Grid>
      }

      <Grid container alignItems="center" sx={{ width: '100%' }}>
        <Grid xs={5}>
          <Typography variant='h6'>Organization Name</Typography>
        </Grid>

        {isLoading
          ? (<Grid xs={7}>
            <Skeleton variant="rectangular" width={'80%'} height={30} />
          </Grid>
          )
          : (isEdit
            ? (
              <Grid xs={8} sx={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                  value={editName}
                  onChange={(t) => {
                    setEditName(t.target.value)
                  }}
                  sx={sxTextField}
                  size='small'
                />
                <Button aria-label='save' variant='contained' onClick={handleSave} sx={{ paddingX: 6 }}>
                  Save
                </Button>

                <Box sx={{ paddingX: '1vh' }} />

                <Button aria-label='cancel' variant='outlined' onClick={handleCancel} sx={{ paddingX: 5 }}>
                  Cancel
                </Button>
              </Grid>
            ) : (
              <>
                <Grid xs={6}>
                  <Typography>{org?.org_name}</Typography>
                </Grid>
                <Grid xs={1} sx={sxEditIcon}>
                  {isAdmin ?
                    (<IconButton
                      onClick={() => startEdit()} aria-label='edit organization name'
                    >
                      <EditIcon fontSize='small' />
                    </IconButton>
                    ) : (<></>)
                  }
                </Grid>
              </>
            )
          )
        }

      </Grid>
    </Card>
  )
}

const sxTextField: SxProps = {
  width: '70vh',
  marginRight: '3vh',
}

const sxEditIcon: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end'
}

export default OrgName