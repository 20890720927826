import React, { useEffect, useMemo, useRef, useState } from 'react'

import { type MRT_ColumnDef, type MRT_Row, type MRT_TableOptions } from 'material-react-table'
import TableAccordion from './TableAccordion'
import { getNumberCell, getLabelCell } from './CustomCells'
import { Mortgage } from '../../client'
import { foldTable, hideTable, FinanceTableProps, getEditOptions } from './financesUtils'
import { useCustomTable } from './useCustomTable'

export default function MortgagesTable({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: FinanceTableProps<'Liabilities', 'Mortgages'>) {

  const [expanded, setExpanded] = useState(true)
  const [dataList, setDataList] = useState<Mortgage[]>([])
  const [newData, setNewData] = useState<Mortgage>()
  const [dsElements, setDsElements] = useState<any>()

  useEffect(() => {
    if (dataInput) {
      setDataList(dataInput as Mortgage[])
    }
  }, [dataInput])

  useEffect(() => {
    if (datasetGroup && datasetGroup['elements']) {
      setDsElements(datasetGroup['elements'])
    }
  }, [datasetGroup])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, dataList))
  }, [foldEmptyTable, dataList])

  const handleCreate: MRT_TableOptions<Mortgage>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...dataList]
    if (newData) {
      updated.push(newData)
      onUpdate(type, subType, updated)
    }
    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<Mortgage>) {
    const updated = [...dataList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(type, subType, updated)
    }
  }

  const columns = useMemo<MRT_ColumnDef<Mortgage>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        minSize: 250,
        size: 250
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        editSelectOptions: getEditOptions(dsElements, 'Owner', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Owner', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Owner': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Type',
        header: 'Type',
        editSelectOptions: getEditOptions(dsElements, 'Type', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Type', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Type': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Type'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 50
      },
      {
        accessorKey: 'Start Date',
        header: 'Start date',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Start date': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Start date'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 50
      },
      {
        accessorKey: 'End Date',
        header: 'End date',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'End date': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['End date'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 50
      },
      {
        accessorKey: 'Interest Rate',
        header: 'Interest Rate',
        Cell: getNumberCell({ style: 'percent' }),
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Interest Rate': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Interest Rate'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        size: 50
      },
      {
        accessorKey: 'Payment',
        header: 'Monthly Payment',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Payment': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Payment'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Original Balance',
        header: 'Original Balance',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Original Balance': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Original Balance'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Current Balance',
        header: 'Current Balance',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Current Balance': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Current Balance'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },

    ],
    [dataList, newData, dsElements, dynamicValues],
  )

  const table = useCustomTable({
    columns,
    data: dataList,
    readOnly: readOnly,
    tableTitle: title,
    onDelete: handleDelete,
    onCreate: handleCreate,
    // onCancel: handleCancel,
  })

  if (hideTable(hideEmptyTable, dataList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title={title} table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
