import { logErr } from '../log'
import { Api, config } from './api'
import { AlertColor } from '@mui/material'

export async function getWebBanner(): Promise<any | null> {
  try {
    const api = await Api()
    const banner = await api.systems.getWebBanner()
    if (banner && banner?.message && banner.message.length > 0) {
      banner.severity = banner.severity ? banner.severity as AlertColor : 'info' as AlertColor
      return banner
    }
    return null
  } catch (error) {
    logErr('getWebBanner', { error })
  }
  return null
}

export async function getSystemSettings() {
  try {
    const api = await Api()
    return await api.systems.getSystem()
  } catch (error) {
    logErr('getSystemSettings', { error })
  }
}
