import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'

import {
  MRT_TableContainer,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table'
import { PersonalDetails } from '../../client'
import { getNumberCell, getLabelCell } from './CustomCells'
import { foldTable, hideTable, FinanceTableWithMemberIdProps, getEditOptions } from './financesUtils'

export default function PersonalDetailsTable({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, memberId, onUpdate }: FinanceTableWithMemberIdProps<'Client Details', 'Personal Details'>) {

  const [dataList, setDataList] = useState<PersonalDetails[]>([])
  const [newData, setNewData] = useState<PersonalDetails>()
  const [dsElements, setDsElements] = useState<any>()

  useEffect(() => {
    if (dataInput) {
      setDataList(dataInput)
    }
  }, [dataInput])

  useEffect(() => {
    console.log("PersonalDetailsTable datasetGroup", datasetGroup)
    if (datasetGroup && datasetGroup['elements']) {
      setDsElements(datasetGroup['elements'])
    }
  }, [datasetGroup])

  const columns = useMemo<MRT_ColumnDef<PersonalDetails>[]>(
    () => [
      {
        accessorKey: 'First Name',
        header: 'First Name',
        muiEditTextFieldProps: ({ row }) => ({
          required: true,
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['First Name'] = event.target.value
            onUpdate(type, subType, updated, memberId)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Last Name',
        header: 'Last Name',
        muiEditTextFieldProps: ({ row }) => ({
          required: true,
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Last Name'] = event.target.value
            onUpdate(type, subType, updated, memberId)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Gender',
        header: 'Gender',
        editVariant: 'select',
        editSelectOptions: getEditOptions(dsElements, 'Gender', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Gender', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Gender'] = event.target.value
            onUpdate(type, subType, updated, memberId)
          },
        }),
        size: 30
      },
      {
        accessorKey: 'Birthdate',
        header: 'Birthdate', // TODO: format check
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Birthdate'] = event.target.value
            onUpdate(type, subType, updated, memberId)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Marital Status',
        header: 'Marital Status',
        editVariant: 'select',
        editSelectOptions: getEditOptions(dsElements, 'Marital Status', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Marital Status', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Marital Status'] = event.target.value
            onUpdate(type, subType, updated, memberId)
          },
        }),
        size: 100
      },
      { // TODO: list
        accessorKey: 'Citizenship',
        header: 'Citizenship',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Citizenship'] = event.target.value
            onUpdate(type, subType, updated, memberId)
          },
        }),
        size: 100
      },
    ],
    [dataList, newData, dsElements, dynamicValues],
  )

  const table = useMaterialReactTable({
    columns,
    data: dataList,
    editDisplayMode: 'cell',
    enableEditing: !readOnly,
    enableRowActions: false,
    positionActionsColumn: 'last',
  })

  return (

    <MRT_TableContainer table={table} />

  )
}
