import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Link, Typography } from '@mui/material'
import { logInfo } from '../../log'
import { cancelSubscription } from '../../service/payApi'
import { StripeSubscription } from '../../client'
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getOrg } from 'service/orgsApi'

interface Props {
  open: boolean
  closeDialog: () => void
  onClose: (refresh?: boolean) => void
  sub: StripeSubscription
}

export default function SubscriptionCancelDialog({ open, closeDialog, onClose, sub }: Props) {
  const queryClient = useQueryClient()
  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })
  const isPartOfEnterprise = !!(org?.enterprise_id)

  const [busy, setBusy] = useState(false)
  const [showErr, setShowErr] = useState(false)
  const [cancelConfirmLoading, setCancelConfirmLoading] = useState(false)

  useEffect(() => {
    logInfo('SubscriptionCancelDialog')
  }, [])

  async function confirmCancelPlan() {
    setBusy(true)
    setCancelConfirmLoading(true)
    setShowErr(false)
    try {
      await cancelSubscription()
      onClose(true) // refresh
    } catch (e) {
      setShowErr(true)
    }
    setCancelConfirmLoading(false)
    setBusy(false)
  }

  return (
    <Dialog open={open}>
      <Box sx={{ display: 'flex', alignItems: 'center', paddingRight: '4px', paddingLeft: '24px', paddingTop: '8px' }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Are You Sure You Want to Cancel?
        </Typography>
        <IconButton aria-label='close' onClick={() => closeDialog()} disabled={busy}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <DialogContentText  >
          We&apos;re sad to see you go! If there&apos;s anything we could have done better, let us know at <Link href="mailto:support@finmate.ai">support@finmate.ai</Link>.
        </DialogContentText>

        {isPartOfEnterprise &&
          <DialogContentText variant='body2'>
            <br />
            All users will be set to expired.
          </DialogContentText>
        }

        <DialogContentText variant='body2'>
          <br />
          Upon cancellation, a prorated credit will be added to your account, usable for any future plans.
        </DialogContentText>

      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <LoadingButton
          onClick={() => confirmCancelPlan()}
          color="secondary"
          loading={cancelConfirmLoading}
          autoFocus
          variant='outlined'
          aria-label='Cancel Subscription'
        >
          Cancel Subscription
        </LoadingButton>
        <Button variant='contained' onClick={() => closeDialog()} color="primary" disabled={cancelConfirmLoading} aria-label='Keep Subscription'>
          Keep Subscription
        </Button>

      </DialogActions>
      {
        showErr &&
        <>
          <Box sx={{ padding: '1vh' }}></Box>
          <Typography color={'red'}>Something went wrong, try again. if issue persists, contact support@finmate.ai</Typography>
        </>
      }
    </Dialog >
  )
}
