import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { CertificateOfDeposit } from '../../client'
import DeleteIcon from '@mui/icons-material/Delete'
import { foldTable, hideTable, FinanceTableProps, getEditOptions } from './financesUtils'
import TableAccordion from './TableAccordion'
import { getNumberCell, getLabelCell } from './CustomCells'
import { useCustomTable } from './useCustomTable'

export default function CertificatesOfDepositTable({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: FinanceTableProps<'Assets', 'Certificates of Deposit'>) {

  const [expanded, setExpanded] = useState(true)
  const [dataList, setDataList] = useState<CertificateOfDeposit[]>([])
  const [newData, setNewData] = useState<CertificateOfDeposit>()
  const [dsElements, setDsElements] = useState<any>()

  useEffect(() => {
    if (dataInput) {
      setDataList(dataInput)
    }
  }, [dataInput])

  useEffect(() => {
    if (datasetGroup && datasetGroup['elements']) {
      setDsElements(datasetGroup['elements'])
    }
  }, [datasetGroup])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, dataList))
  }, [foldEmptyTable, dataList])

  const handleCreate: MRT_TableOptions<CertificateOfDeposit>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...dataList]
    if (newData) {
      updated.push(newData)
      onUpdate(type, subType, updated)
    }
    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<CertificateOfDeposit>) {
    const updated = [...dataList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(type, subType, updated)
    }
  }
  const columns = useMemo<MRT_ColumnDef<CertificateOfDeposit>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevNewData) => ({
                ...prevNewData,
                Name: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...dataList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 130
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        editSelectOptions: getEditOptions(dsElements, 'Owner', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Owner', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevNewData) => ({
                ...prevNewData,
                Owner: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...dataList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Where Held?',
        header: 'Where Held?',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevNewData) => ({
                ...prevNewData,
                ['Where Held?']: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...dataList]
            updated[rowIdx]['Where Held?'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 100
      },

      {
        accessorKey: 'Interest Rate',
        header: 'Interest Rate',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevNewData) => ({
                ...prevNewData,
                ['Interest Rate']: Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...dataList]
            updated[rowIdx]['Interest Rate'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        Cell: getNumberCell({ style: 'percent' }),
        size: 100
      },
      {
        accessorKey: 'Current Value',
        header: 'Current Value',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevNewData) => ({
                ...prevNewData,
                ['Current Value']: Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...dataList]
            updated[rowIdx]['Current Value'] = Number(event.target.value)
            onUpdate(type, subType, updated)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Maturity Date',
        header: 'Maturity Date',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevNewData) => ({
                ...prevNewData,
                ['Maturity Date']: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...dataList]
            updated[rowIdx]['Maturity Date'] = event.target.value
            onUpdate(type, subType, updated)
          },
        }),
        size: 100
      },
    ],
    [dataList, newData, dsElements, dynamicValues],
  )

  const table = useCustomTable({
    columns,
    data: dataList,
    readOnly: readOnly,
    tableTitle: title,
    onDelete: handleDelete,
    onCreate: handleCreate,
    // onCancel: handleCancel,
  })

  if (hideTable(hideEmptyTable, dataList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title={title} table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
