import React from 'react'
import { OrgBase, OrgUserRole, ProductName, UserUsage } from '../../client'
import { Box, SxProps, Typography } from '@mui/material'
import UpgradeButton from '../pay/UpgradeButton'
import { auth } from 'service/api'
import { getEnterpriseParent, getOrg, getOrgUser } from 'service/orgsApi'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getUsage } from 'service'

export default function HoursRemaining() {
  const queryClient = useQueryClient()
  const uid = auth?.currentUser?.uid

  const { data: usage } = useQuery({
    queryKey: ['getUsage'], queryFn: getUsage,
    enabled: queryClient.getQueryData(['getUsage']) === undefined,
  })

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })
  const orgUser = getOrgUser(org, uid)

  const { data: ent } = useQuery({
    queryKey: ['getEnterpriseParent'], queryFn: getEnterpriseParent,
    enabled: queryClient.getQueryData(['getEnterpriseParent']) === undefined,
  })

  if (orgUser?.org_user_role === OrgUserRole.INACTIVE
    || orgUser?.org_user_plan !== ProductName.FREE_TRIAL
  )
    return null

  return (
    <>
      <Box sx={sxProfile}>
        <Typography sx={sxFree} variant='caption'>
          {`${usage?.free_hours_remaining?.toFixed(2)} Meeting Hours Remaining on your Free Trial`}
        </Typography>
        {/* <Box sx={{ padding: 1 }} /> */}
        <UpgradeButton />

        {
          usage?.free_hours_remaining === 0 &&
          <>
            <Box sx={{ padding: 1 }} />
            <Typography variant='caption'>
              {'We are here to help. Contact us at the bottom right for any questions.'}
            </Typography>
          </>
        }
      </Box>
      {/* <Divider /> */}
    </>
  )

}
const sxFree: SxProps = {
  fontWeight: 'bold',
  color: 'red',

}

const sxProfile: SxProps = {
  py: 1.5,
  px: 2,
  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
  // backgroundColor: 'blue',
}