/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnterpriseParent } from '../models/EnterpriseParent';
import type { OrgBase } from '../models/OrgBase';
import type { OrgConfigs } from '../models/OrgConfigs';
import type { OrgInvite } from '../models/OrgInvite';
import type { OrgUser } from '../models/OrgUser';
import type { OrgUserRole } from '../models/OrgUserRole';
import type { OrgUserStatus } from '../models/OrgUserStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OrgsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Org
     * @returns OrgBase Successful Response
     * @throws ApiError
     */
    public getOrg(): CancelablePromise<OrgBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orgs/',
        });
    }

    /**
     * Patch Org Name
     * @param orgName
     * @returns any Successful Response
     * @throws ApiError
     */
    public setOrgName(
        orgName: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/orgs/name',
            query: {
                'org_name': orgName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Org User
     * @param requestBody
     * @returns OrgUser Successful Response
     * @throws ApiError
     */
    public updateOrgUser(
        requestBody: OrgUser,
    ): CancelablePromise<Array<OrgUser>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/orgs/users/update_org_user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Org Configs
     * @returns OrgConfigs Successful Response
     * @throws ApiError
     */
    public getOrgConfigs(): CancelablePromise<OrgConfigs> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orgs/configs',
        });
    }

    /**
     * Update Org Configs
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateOrgConfigs(
        requestBody: OrgConfigs,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/orgs/configs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Org Invites As Recipient
     * @returns OrgInvite Successful Response
     * @throws ApiError
     */
    public getOrgInvitesAsRecipient(): CancelablePromise<Array<OrgInvite>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orgs/invite/target',
        });
    }

    /**
     * Get Org Invites
     * @returns OrgInvite Successful Response
     * @throws ApiError
     */
    public getOrgInvites(): CancelablePromise<Array<OrgInvite>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orgs/invite',
        });
    }

    /**
     * Create Org Invites
     * @param role
     * @param requestBody
     * @returns OrgInvite Successful Response
     * @throws ApiError
     */
    public createOrgInvites(
        role: OrgUserRole,
        requestBody: Array<string>,
    ): CancelablePromise<Array<OrgInvite>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orgs/invite',
            query: {
                'role': role,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Answer Org Invites
     * @param orgInviteId
     * @param setStatus
     * @returns OrgInvite Successful Response
     * @throws ApiError
     */
    public answerOrgInvite(
        orgInviteId: string,
        setStatus: OrgUserStatus,
    ): CancelablePromise<OrgInvite> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/orgs/invite/answer',
            query: {
                'org_invite_id': orgInviteId,
                'set_status': setStatus,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Enterprise Parent
     * @returns EnterpriseParent Successful Response
     * @throws ApiError
     */
    public getEnterpriseParent(): CancelablePromise<EnterpriseParent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/orgs/enterprise-parent',
        });
    }

}
