import React, { useEffect, useMemo, useRef, useState } from 'react'

import { type MRT_ColumnDef, type MRT_Row, type MRT_TableOptions } from 'material-react-table'

import TableAccordion from './TableAccordion'
import { getNumberCell, getLabelCell } from './CustomCells'
import { ClientData, RetirementPlanEmployer } from '../../client'
import { foldTable, hideTable, FinanceTableProps, getEditOptions } from './financesUtils'
import { useCustomTable } from './useCustomTable'

export default function RetirementPlansEmployerTable<
  T extends keyof ClientData,
  S extends keyof NonNullable<ClientData[T]>
>({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, onUpdate }: FinanceTableProps<T, S>) {

  const [expanded, setExpanded] = useState(true)
  const [dataList, setDataList] = useState<RetirementPlanEmployer[]>([])
  const [newData, setNewData] = useState<RetirementPlanEmployer>()
  const [dsElements, setDsElements] = useState<any>()

  useEffect(() => {
    if (dataInput) {
      setDataList(dataInput as RetirementPlanEmployer[])
    }
  }, [dataInput])

  useEffect(() => {
    if (datasetGroup && datasetGroup['elements']) {
      setDsElements(datasetGroup['elements'])
    }
  }, [datasetGroup])

  useEffect(() => {
    setExpanded(foldTable(foldEmptyTable, dataList))
  }, [foldEmptyTable, dataList])

  const handleCreate: MRT_TableOptions<RetirementPlanEmployer>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...dataList]
    if (newData) {
      updated.push(newData)
      onUpdate(type, subType, updated as NonNullable<ClientData[T]>[S])
    }
    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<RetirementPlanEmployer>) {
    const updated = [...dataList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(type, subType, updated as NonNullable<ClientData[T]>[S])
    }
  }

  const columns = useMemo<MRT_ColumnDef<RetirementPlanEmployer>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(type, subType, updated as NonNullable<ClientData[T]>[S])
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        editSelectOptions: getEditOptions(dsElements, 'Owner', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Owner', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Owner': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(type, subType, updated as NonNullable<ClientData[T]>[S])
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Employer Contributions',
        header: 'Employer Contributions',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Employer Contributions': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Employer Contributions'] = Number(event.target.value)
            onUpdate(type, subType, updated as NonNullable<ClientData[T]>[S])
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Employee Contributions',
        header: 'Employee Contributions',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Employee Contributions': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Employee Contributions'] = Number(event.target.value)
            onUpdate(type, subType, updated as NonNullable<ClientData[T]>[S])
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Account Value',
        header: 'Account Value',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Account Value': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Account Value'] = Number(event.target.value)
            onUpdate(type, subType, updated as NonNullable<ClientData[T]>[S])
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Primary Beneficiary',
        header: 'Primary Beneficiary',
        editSelectOptions: getEditOptions(dsElements, 'Primary Beneficiary', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Primary Beneficiary', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Primary Beneficiary': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Primary Beneficiary'] = event.target.value
            onUpdate(type, subType, updated as NonNullable<ClientData[T]>[S])
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Secondary Beneficiary',
        header: 'Secondary Beneficiary',
        editSelectOptions: getEditOptions(dsElements, 'Secondary Beneficiary', dynamicValues),
        Cell: getLabelCell(() => getEditOptions(dsElements, 'Secondary Beneficiary', dynamicValues)),
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onBlur: (event) => {
            if (row.index < 0) {
              setNewData((prevPlan) => ({
                ...prevPlan,
                'Secondary Beneficiary': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Secondary Beneficiary'] = event.target.value
            onUpdate(type, subType, updated as NonNullable<ClientData[T]>[S])
          },
        }),
        size: 100
      },
    ],
    [dataList, newData, dsElements, dynamicValues],
  )

  const table = useCustomTable({
    columns,
    data: dataList,
    readOnly: readOnly,
    tableTitle: title,
    onDelete: handleDelete,
    onCreate: handleCreate,
    // onCancel: handleCancel,
  })

  if (hideTable(hideEmptyTable, dataList)) {
    return (
      <></>
    )
  }

  return (
    <TableAccordion title={title} table={table} readOnly={readOnly} expanded={expanded} setExpanded={setExpanded} />
  )
}
