import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, SxProps, Typography, Card, CardHeader, CardContent, Snackbar, Alert, Backdrop, CircularProgress } from '@mui/material'
import { EventBase, ClientData } from '../../../client'
import { Media } from '../../../service/media'
import { gSx } from '../../../styles/Theme'
import { Path } from '../../../routes'
import { useNavigate } from 'react-router-dom'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import PrecisefpContactCard from './PrecisefpContactCard'
import FinancesTables from 'components/finances/FinancesTables'
import { LoadingButton } from '@mui/lab'
import { Api } from 'service/api'
import { getErrorMessage } from "utils"
import { logInfo, logErr } from 'log'

interface Props {
  open: boolean
  onClose: () => void
  event: EventBase
  clientData?: ClientData
  datasetByTitle?: any
  dynamicValues?: any
}

export default function PrecisefpDialog({ open, onClose, event, clientData, datasetByTitle, dynamicValues }: Props) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { isBlocked } = useIsBlockedByPlan(true)
  const [busy, setBusy] = useState(false)
  const [openErrorSnack, setOpenErrorSnack] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState<string | undefined>()
  const hasClient = event?.client?.id ?? false
  const disableSend = isBlocked || !hasClient || busy

  useEffect(() => {
    if (open) {
      logInfo('Precisefp Dialog Open')
      queryClient.invalidateQueries({ queryKey: ['getEvent', event?.id] })
    }
  }, [open])

  async function handleSend() {
    setBusy(true)
    if (event?.id) {
      try {
        const api = await Api()
        const res_person = await api.precisefp.postPrecisePfPerson(event?.id)
        const res_profile = await api.precisefp.postPrecisePfProfile(event?.id)
        setOpenSuccessSnack("Successfully synced with PreciseFP")
        queryClient.invalidateQueries({ queryKey: ['getEvent', event?.id] })
      } catch (e) {
        const err = getErrorMessage(e)
        logErr("handleSend failed", { err })
        setOpenErrorSnack(err.message)
      }
    }
    setBusy(false)
  }

  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose}
      PaperProps={{ style: { height: '98vh', margin: 0, maxHeight: '98vh' } }}
    >
      <DialogTitle sx={gSx.Row}>
        <Box component="img" sx={icon} src={Media.precisefpIcon} alt="PreciseFP" />
        <Box>
          <Typography variant='h3'>Send to PreciseFP</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            The data provided will be appended to an existing contact in PreciseFP if their email matches. If no match is found, a new profile will be created using this data.
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        {isBlocked &&
          <Typography sx={sxText}>{`Please `}
            <Link onClick={() => navigate(Path.Billing)}>Upgrade your plan</Link>
          </Typography>
        }
        <Box sx={{ padding: 1 }} />

        <PrecisefpContactCard event={event} />
        <Card>
          <CardHeader
            title="Review Client's Finances"
            sx={{ paddingTop: 1 }}
          />
          <CardContent sx={{ padding: 0 }}>
            <FinancesTables eventId={event?.id} clientData={clientData} datasetByTitle={datasetByTitle} dynamicValues={dynamicValues} hideEmptyTables={true} foldEmptyTables={true} readOnly={true} />
          </CardContent >
        </Card>

      </DialogContent>

      <DialogActions sx={{ gap: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton loading={busy} variant="contained" disabled={disableSend} onClick={handleSend}>Send to PreciseFP</LoadingButton>
      </DialogActions>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openErrorSnack}
        autoHideDuration={6000}
        onClose={() => setOpenErrorSnack(undefined)}
      >
        <Alert variant='filled' severity='error'>
          {openErrorSnack}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openSuccessSnack}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSuccessSnack(undefined)
          onClose()
        }}
      >
        <Alert variant='filled' severity='success'>
          {openSuccessSnack}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={busy}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog >

  )
}

const icon: SxProps = {
  width: 40,
  height: 40,
  marginRight: 2
}

const sxText: SxProps = {
  paddingY: '5px',
  cursor: 'pointer'
}
