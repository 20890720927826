import React, { useState } from "react"
import { OrgBase } from "../../client"
import { Box, Card, IconButton, Skeleton, SxProps, Typography } from "@mui/material"
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getCustomer } from "../../service/payApi"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { getOrg, isAdminRole } from "../../service/orgsApi"
import EditIcon from '@mui/icons-material/Edit'
import ChooseBillingContact from "./ChooseBillingContact"

export default function StripeCustomerInfo() {
  const queryClient = useQueryClient()

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })
  const isAdmin = isAdminRole(org)

  const [showModal, setModal] = useState(false)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getCustomer'],
    queryFn: async () => await getCustomer(),
  })

  const isEmailNull = data?.email === null // no stripe plan setup yet.

  return (
    <Box>
      <Grid container alignItems="center" sx={{ minHeight: '100%' }}>
        <Grid xs={5} >
          <Typography variant='h6' >Billing Contact</Typography>
        </Grid>

        <Grid xs={6}>
          {isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={20} />
            : <Typography sx={{ paddingLeft: '6px' }}>{`${data?.email ?? ''}`}</Typography>
          }
        </Grid>

        <Grid xs={1} sx={sxEditIcon}>
          {!isLoading && isAdmin && !isEmailNull && (
            <IconButton onClick={() => setModal(true)}>
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>

      </Grid>

      <ChooseBillingContact
        customer={data}
        setModal={setModal}
        showModal={showModal}
        refetch={refetch}
      />
    </Box>
  )
}

const sxEditIcon: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end'
}
