import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import { PersonalDetails, Client, Employment, ClientDetails, ClientData } from '../../client'
import PersonalDetailsTable from './PersonalDetailsTable'
import EmploymentTable from './EmploymentTable'
import SubCard from 'components/SubCard'
import { UpdateClientDataInterface } from './financesUtils'

interface Props {
  eventId?: string
  clientData?: ClientData
  datasetByTitle?: any
  dynamicValues?: any
  readOnly: boolean
  onUpdate: UpdateClientDataInterface
}

export default function ClientList({ eventId, clientData, datasetByTitle, dynamicValues, readOnly, onUpdate }: Props) {

  const [clientMap, setClientMap] = useState<any>({})
  const [memberIdList, setMemberIdList] = useState<any[]>([])

  useEffect(() => {
    const idList: any[] = []
    const updatedMap: any = {}
    if (clientData?.['Client Details']?.['Personal Details']) {
      const pds = clientData?.['Client Details']?.['Personal Details']
      for (const pd of pds) {
        if (pd.member_id) {
          idList.push(pd.member_id)
          updatedMap[pd.member_id] = {
            'Personal Details': [pd]
          }
        }
      }
    }
    if (clientData?.['Employment & Income']?.['Employment']) {
      const emps = clientData?.['Employment & Income']?.['Employment']
      for (const em of emps) {
        if (em.member_id && idList.includes(em.member_id) && em.member_id in updatedMap) {
          updatedMap[em.member_id]['Employment'] = [em]
        }
      }
    }

    setMemberIdList(idList)
    setClientMap(updatedMap)
  }, [clientData])

  async function onUpdateOneMember<
    P extends keyof ClientData,
    S extends keyof NonNullable<ClientData[P]>,
  >(
    type: P,
    subType: S,
    updated: NonNullable<ClientData[P]>[S],
    memberId: string
  ) {

    const updatedClientData = { ...clientData }
    if (!updatedClientData[type]) {
      updatedClientData[type] = {} as NonNullable<ClientData[P]>
    }

    if (!(updatedClientData[type] as NonNullable<ClientData[P]>)[subType]) {
      (updatedClientData[type] as NonNullable<ClientData[P]>)[subType] = [] as NonNullable<ClientData[P]>[S]
    }

    const currentArray = (updatedClientData[type] as NonNullable<ClientData[P]>)[subType] as Array<{ member_id: string }>

    const memberIndex = currentArray.findIndex(item => item.member_id === memberId)

    if (memberIndex !== -1) {
      currentArray[memberIndex] = {
        ...currentArray[memberIndex],
        ...updated
      }
    } else {
      currentArray.push({ member_id: memberId, ...updated })
    }
    onUpdate<P, S>(type, subType, currentArray as NonNullable<ClientData[P]>[S])
  }

  return (
    <>
      {memberIdList.map((memberId) => (
        <SubCard key={memberId}>
          <CardHeader
            title={`${clientMap[memberId]?.['Personal Details'][0]['First Name'] ?? ""} ${clientMap[memberId]?.['Personal Details'][0]['Last Name'] ?? ""}`.trim()}
            sx={{ paddingTop: 1 }}
          />

          <PersonalDetailsTable title="" type={"Client Details"} subType={"Personal Details"} eventId={eventId} dataInput={clientMap[memberId]?.['Personal Details']} onUpdate={onUpdateOneMember<"Client Details", 'Personal Details'>} datasetGroup={datasetByTitle?.['Client Details']?.['groups']?.['Personal Details']} dynamicValues={dynamicValues} memberId={memberId} readOnly={readOnly} hideEmptyTable={false} foldEmptyTable={false} />

          <Box sx={{ padding: '1vh' }} />

          <EmploymentTable title="" type={"Employment & Income"} subType={"Employment"} eventId={eventId} dataInput={clientMap[memberId]?.['Employment']} onUpdate={onUpdateOneMember<"Employment & Income", 'Employment'>} datasetGroup={datasetByTitle?.['Employment & Income']?.['groups']?.['Employment']} dynamicValues={dynamicValues} memberId={memberId} readOnly={readOnly} hideEmptyTable={false} foldEmptyTable={false} />

        </SubCard>
      ))}
    </>
  )
}
