// useCustomTable.ts or useCustomTable.tsx
import { useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table'
import { Box, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

interface UseCustomTableProps<TData extends Record<string, any>> {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  readOnly?: boolean;
  tableTitle?: string;
  onDelete?: (row: any) => void;
  onCreate?: (data: any) => void;
  onCancel?: () => void;
}

export function useCustomTable<TData extends Record<string, any>>({
  columns,
  data,
  readOnly = false,
  tableTitle = "",
  onDelete,
  onCreate,
  onCancel,
}: UseCustomTableProps<TData>) {
  return useMaterialReactTable({
    columns,
    data,
    editDisplayMode: 'cell',
    createDisplayMode: 'modal',
    localization: {
      edit: tableTitle ? 'Add New ' + tableTitle : 'Add New Entry',
    },
    enableEditing: !readOnly,
    enableRowActions: !readOnly,
    positionActionsColumn: 'last',
    onCreatingRowCancel: () => onCancel?.(),
    onCreatingRowSave: onCreate,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete" >
          <IconButton onClick={() => onDelete?.(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    // Disable toolbars and features
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    enableGlobalFilter: false,
    enablePagination: false,
    enableDensityToggle: false,
    enableColumnResizing: false,
    enableHiding: false,
  })
}