import React, { useEffect, useState } from 'react'

import { ipInfo } from 'service'

import { useStore } from 'hooks/useStore'
import Grid2 from '@mui/material/Unstable_Grid2'
import useWindowWidth from 'hooks/useWindowWidth'
import { UploadButton } from 'components/recorder/UploadButton'
import ZoomSetupAtHome from 'components/settings/ZoomSetupAtHome'
import { OpenWebRecorderButton } from 'components/recorder/WebRecorderButton'
import { Box, Grid, SxProps, useMediaQuery, useTheme } from '@mui/material'

import { logInfo } from '../log'
import Guide from '../components/help/DashboardGuide'
import EventsList from '../components/event/EventsList'
import BotJoinByUrl from '../components/calendar/BotJoinByUrl'
import { requestNotificationPermission } from '../service/msgApi'
import ListCalendarEventsShort from '../components/calendar/ListCalendarEventsShort'
import ReceivedInvitesForHome from 'components/settings/ReceivedInvitesForHome'
import ExpiredPlanBanner from 'components/pay/ExpiredPlanBanner'
import WebBanner from 'components/WebBanner'

export default function Home() {
  const store = useStore()
  const windowWidth = useWindowWidth()
  const theme = useTheme()
  const [isMd, setIsMd] = useState(false)

  useEffect(() => {
    logInfo('Home Screen')
    ipInfo()
    store.User.setEventSearchParams(undefined)
  }, [])

  useEffect(() => {
    // Refresh the token every week
    requestNotificationPermission()
    const interval = setInterval(requestNotificationPermission, 604800000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMd(window.innerWidth < theme.breakpoints.values.lg)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [theme.breakpoints.values.lg])

  return (
    <>

      <Grid2 container spacing={2}>

        <Grid2 xs={12} xl={8}>
          <Guide />
          <ExpiredPlanBanner />

          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <BotJoinByUrl />
            </Grid>
            {
              !isMd &&
              <Grid item xs={0} lg={4}>
                <Box sx={sxActionLarge}>
                  <UploadButton />
                  <Box sx={{ padding: 1 }} />
                  <OpenWebRecorderButton />
                </Box>
              </Grid>
            }
          </Grid>

          {
            isMd &&
            <>
              <Box sx={{ maxWidth: windowWidth }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} sx={sxCenter}>
                    <UploadButton />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={sxCenter}>
                    <OpenWebRecorderButton />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ padding: 1 }} />
            </>
          }

          <ReceivedInvitesForHome />

          <EventsList />
        </Grid2>

        <Grid2 xs={12} xl={4}>
          <WebBanner />
          <ZoomSetupAtHome />
          <ListCalendarEventsShort />
        </Grid2>
      </Grid2>
    </>
  )
}

const sxCenter: SxProps = {
  display: 'flex',
  justifyContent: 'center'
}

const sxActionLarge: SxProps = {
  paddingY: 0.5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}