import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  MRT_TableContainer,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { ClientData, EmploymentIncome, Employment } from '../../client'
import { getNumberCell } from './CustomCells'
import { foldTable, hideTable, FinanceTableWithMemberIdProps, getEditOptions } from './financesUtils'

export default function EmploymentTable({ eventId, title, type, subType, dataInput, datasetGroup, dynamicValues, hideEmptyTable, foldEmptyTable, readOnly, memberId, onUpdate }: FinanceTableWithMemberIdProps<'Employment & Income', 'Employment'>) {

  const [dataList, setDataList] = useState<Employment[]>([])
  const [newData, setNewData] = useState<Employment>()
  const [dsElements, setDsElements] = useState<any>()

  useEffect(() => {
    if (dataInput) {
      setDataList(dataInput)
    }
  }, [dataInput])

  useEffect(() => {
    console.log("Employment datasetGroup", datasetGroup)
    if (datasetGroup && datasetGroup['elements']) {
      setDsElements(datasetGroup['elements'])
    }
  }, [datasetGroup])

  const columns = useMemo<MRT_ColumnDef<Employment>[]>(
    () => [
      {
        accessorKey: 'Employer',
        header: 'Employer',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Employer'] = event.target.value
            onUpdate(type, subType, updated, memberId)
          },
        }),
        size: 140
      },
      {
        accessorKey: 'Title / Job Position',
        header: 'Title / Job Position',
        muiEditTextFieldProps: ({ row }) => ({
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Title / Job Position'] = event.target.value
            onUpdate(type, subType, updated, memberId)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'No. of Years Working Here',
        header: 'No. of Years Working Here',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['No. of Years Working Here'] = Number(event.target.value)
            onUpdate(type, subType, updated, memberId)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Salary & Tips',
        header: 'Salary & Tips',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Salary & Tips'] = Number(event.target.value)
            onUpdate(type, subType, updated, memberId)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
      {
        accessorKey: 'Bonus & Commissions',
        header: 'Bonus & Commissions',
        muiEditTextFieldProps: ({ row }) => ({
          type: 'number',
          onBlur: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...dataList]
            updated[rowIdx]['Bonus & Commissions'] = Number(event.target.value)
            onUpdate(type, subType, updated, memberId)
          },
        }),
        Cell: getNumberCell(),
        size: 100
      },
    ],
    [dataList, newData, dsElements, dynamicValues],
  )

  const table = useMaterialReactTable({
    columns,
    data: dataList,
    editDisplayMode: 'cell',
    enableEditing: !readOnly,
    enableRowActions: false,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
  })

  return (
    <MRT_TableContainer table={table} />
  )
}
