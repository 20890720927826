import React, { useState } from 'react'
import { Typography, Skeleton, CardHeader, Card, Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { getEnterpriseParent, getOrg } from 'service/orgsApi'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getSubscription } from 'service/payApi'
import { numStandardToOrgFromEnterprise, numStarterToOrgFromEnterprise } from 'service/enterpriseApi'

export default function SeatsPurchased() {
  const queryClient = useQueryClient()

  const { data: ent } = useQuery({
    queryKey: ['getEnterpriseParent'], queryFn: getEnterpriseParent,
  })
  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
  })
  const { data: sub, isLoading } = useQuery({
    queryKey: ['getSubscription'], queryFn: getSubscription,
  })
  const standardInitToOrgByEnt = numStandardToOrgFromEnterprise(ent, org)
  const starterInitToOrgByEnt = numStarterToOrgFromEnterprise(ent, org)

  function EntNumbers() {
    if (isLoading || !sub)
      return <Skeleton variant="rectangular" width={'100%'} height={40} />

    return (
      <>
        <Typography>{standardInitToOrgByEnt}</Typography>
        <Typography>{starterInitToOrgByEnt}</Typography>
      </>
    )
  }

  function OrgNumbers() {
    if (isLoading || !sub)
      return <Skeleton variant="rectangular" width={'100%'} height={40} />

    return (
      <>
        <Typography>{sub.standard?.quantity ?? 0}</Typography>
        <Typography>{sub.starter?.quantity ?? 0}</Typography>
        <Typography>{sub.lite?.quantity ?? 0}</Typography>
      </>
    )
  }

  return (
    <Card sx={{ margin: 0, flexGrow: 1 }}>
      <CardHeader title="Seats Purchased" />

      {ent &&
        <>
          <Typography variant='h6'>{`By Enterprise - ${ent?.name}`}</Typography>
          <Grid container>
            <Grid xs={11}>
              <Typography>Standard Seats</Typography>
              <Typography>Starter Seats</Typography>
            </Grid>

            <Grid xs={1}>
              <EntNumbers />
            </Grid>
          </Grid>

          <Box sx={{ padding: 1 }} />
          <Typography variant='h6'>{`By Organization - ${org?.org_name}`}</Typography>
        </>
      }
      <Grid container>
        <Grid xs={11}>
          <Typography>Standard Seats</Typography>
          <Typography>Starter Seats</Typography>
          <Typography>Lite Seats</Typography>
        </Grid>

        <Grid xs={1}>
          <OrgNumbers />
        </Grid>
      </Grid>

    </Card>
  )
}