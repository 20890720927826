import React, { useState } from 'react'
import { Typography, CardHeader, Card, Select, MenuItem } from '@mui/material'
import { getOrg } from '../../service/orgsApi'
import { EnterpriseUser } from 'client'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getEnterprise } from 'service/enterpriseApi'
import { MaterialReactTable, MRT_ColumnDef, MRT_Row, useMaterialReactTable } from 'material-react-table'
import { SkeletonRows } from 'components/Skeletons'

interface Props {
  row: MRT_Row<EnterpriseUser>;
}

export default function EnterpriseRolesCard() {
  const queryClient = useQueryClient()

  const { data: ent, isLoading } = useQuery({
    queryKey: ['getEnterprise'], queryFn: getEnterprise,
    enabled: queryClient.getQueryData(['getEnterprise']) === undefined,
  })

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })

  const columns: MRT_ColumnDef<EnterpriseUser>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      enableEditing: false,
      size: 200,
    },
    {
      accessorKey: 'email',
      header: 'Email',
      enableEditing: false,
      size: 200,
    },
    {
      accessorKey: 'role',
      header: 'Role',
      size: 50,
      Cell: ({ row }: Props) => (
        <Select
          size='small'
          value={row.original.role}
          // onChange={(event) => handleRoleChange(event, row)}
          disabled={true} // Adjust based on your requirements
        >
          <MenuItem value="Admin">Admin</MenuItem>
          <MenuItem value="Viewer">Viewer</MenuItem>
          <MenuItem value="Remove">Remove</MenuItem>
        </Select>
      ),
    },
  ]

  const table = useMaterialReactTable({
    columns,
    data: ent?.users ?? [],
    enableEditing: false, // Disable default editing behavior
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableHiding: false,
    positionGlobalFilter: 'left',
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: '#fdfdfd', // Set background color for the entire table
      },
    },
    getRowId: (row) => row.uid ?? '',
    initialState: { columnPinning: { right: ['actions'] }, density: 'compact' },
  })

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader
        title='Enterprise Roles'
      />
      <Typography>{`Your organization ${org?.org_name}, controls the Enterprise ${ent?.name}.`}</Typography>

      <Typography>{`The organization's Admin is the same as the Enterprise Admin`}</Typography>

      {isLoading
        ? <SkeletonRows count={3} height={70} />
        : <MaterialReactTable table={table} />
      }
    </Card>
  )
}