import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, SxProps, TextField, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { logInfo } from 'log'
import { getEnterprise, getEnterpriseSubscription, isEnterpriseAdmin, numStandardAllocatedToOrgs, numStandardToOrgFromEnterprise, numStarterAllocatedToOrgs, numStarterToOrgFromEnterprise, updateEnterpriseOrgSeats } from 'service/enterpriseApi'
import { OrgBase, ProductName } from 'client'
import { LoadingButton } from '@mui/lab'
import EditIcon from '@mui/icons-material/Edit'
import Grid from '@mui/material/Unstable_Grid2'
import { numActiveOrgUsersByPlan } from 'service/orgsApi'
import { getOrgSubscriptionAsEnterpriseAdmin } from 'service/payApi'
import { auth } from 'service/api'
import { SkeletonRows } from 'components/Skeletons'

interface Props {
  org: OrgBase
}
export default function EnterpriseOrgEditSeatsDialog({ org }: Props) {
  const navigate = useNavigate()
  const authEmail = auth.currentUser?.email ?? undefined
  const queryClient = useQueryClient()
  const [showDialog, setDialog] = useState(false)
  const [standard, setStandard] = useState(0)
  const [starter, setStarter] = useState(0)
  const [busy, setBusy] = useState(false)
  const [frontErr, setFrontErr] = useState<string | undefined>() // frontend errors
  const [err, setErr] = useState<string | undefined>() // api errors
  const [successSnack, setSuccessSnack] = useState(false)

  const { data: entSub, isLoading: isLoadingEntSub } = useQuery({
    queryKey: ['getEnterpriseSubscription'], queryFn: getEnterpriseSubscription,
  })
  const { data: ent, isLoading: isLoadingEnterprise } = useQuery({
    queryKey: ['getEnterprise'], queryFn: getEnterprise,
  })
  const { data: orgSub, isLoading: isLoadingOrgSub } = useQuery({
    queryKey: ['getOrgSubscriptionAsEnterpriseAdmin', org.org_id],
    queryFn: async () => await getOrgSubscriptionAsEnterpriseAdmin(org.org_id),
  })
  const isLoading = isLoadingEntSub || isLoadingEnterprise || isLoadingOrgSub
  const isEntAdmin = isEnterpriseAdmin(ent)
  const standardPaidByEnt = entSub?.standard?.quantity ?? 0
  const starterPaidByEnt = entSub?.starter?.quantity ?? 0
  const standardAllocatedToOrgs = numStandardAllocatedToOrgs(ent)
  const starterAllocatedToOrgs = numStarterAllocatedToOrgs(ent)
  const standardInitToOrg = numStandardToOrgFromEnterprise(ent, org)
  const starterInitToOrg = numStarterToOrgFromEnterprise(ent, org)
  const standardAvailableByEnt = standardPaidByEnt - standardAllocatedToOrgs - (standard - standardInitToOrg)
  const starterAvailableByEnt = starterPaidByEnt - starterAllocatedToOrgs - (starter - starterInitToOrg)

  useEffect(() => {
    if (showDialog) {
      logInfo('Add Client Start Pressed')
      setStandard(standardInitToOrg)
      setStarter(starterInitToOrg)
    }
  }, [showDialog])

  useEffect(() => { // Front end validation
    if (isLoading)
      return

    const standardOrgPaid = orgSub?.standard?.quantity ?? 0
    const starterOrgPaid = orgSub?.starter?.quantity ?? 0
    const standardOrgUsed = numActiveOrgUsersByPlan(ProductName.STANDARD, org.org_user_list)
    const starterOrgUsed = numActiveOrgUsersByPlan(ProductName.STARTER, org.org_user_list)

    if (standardOrgPaid + standard < standardOrgUsed) {
      setFrontErr('Cannot allocate less standard plans than what the organization is currently using.')
    } else if (starterOrgPaid + starter < starterOrgUsed) {
      setFrontErr('Cannot allocate less starter plans than what the organization is currently using.')
    } else {
      setFrontErr(undefined)
    }

    if (standard < 0) // min
      setStandard(0)
    else if (standardAvailableByEnt < 0) // max
      setStandard(standardPaidByEnt - standardAllocatedToOrgs + standardInitToOrg)

    if (starter < 0) // min
      setStarter(0)
    else if (starterAvailableByEnt < 0) // max
      setStarter(starterPaidByEnt - starterAllocatedToOrgs + starterInitToOrg)

  }, [standard, starter, orgSub, isLoading])

  function onClose() {
    if (busy)
      return
    setDialog(false)
  }
  async function onSave() {
    setBusy(true)
    setErr(undefined)

    const res = await updateEnterpriseOrgSeats(org.org_id, standard, starter)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSuccessSnack(true)
      await queryClient.invalidateQueries({ queryKey: ['getEnterprise'] })
      setDialog(false)
    }
    setBusy(false)
  }

  return (
    <>
      <Tooltip title={isEntAdmin ? '' : 'Requires Enterprise Admin Role'}>
        <IconButton
          aria-label='edit'
          onClick={() => setDialog(true)}
          disabled={!isEntAdmin}
        >
          <EditIcon fontSize='small' />
        </IconButton>
      </Tooltip>

      <Dialog open={showDialog} onClose={onClose}>

        <DialogTitle>Allocate Licenses for {org.org_name}</DialogTitle>

        {isLoading
          ? <SkeletonRows count={3} height={50} />
          : <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid sm={4} >
                <b>Plan Type</b>
              </Grid>
              <Grid sm={4}>
                <b>Available</b>
              </Grid>
              <Grid sm={4}>
                <b>Allocate</b>
              </Grid>

              <Grid sm={4}>
                Standard
              </Grid>
              <Grid sm={4}>
                {standardAvailableByEnt}
              </Grid>
              <Grid sm={4}>
                <TextField
                  autoFocus
                  label='Standard Seats'
                  variant="outlined"
                  type="number"
                  value={standard}
                  onChange={(t) => setStandard(parseInt(t.target.value))}
                  sx={sxText}
                  disabled={busy}
                />
              </Grid>

              <Grid sm={4}>
                Starter
              </Grid>
              <Grid sm={4}>
                {starterAvailableByEnt}
              </Grid>
              <Grid sm={4}>
                <TextField
                  autoFocus
                  label='Starter Seats'
                  variant="outlined"
                  type="number"
                  value={starter}
                  onChange={(t) => setStarter(parseInt(t.target.value))}
                  sx={sxText}
                  disabled={busy}
                />
              </Grid>
            </Grid>

            <Typography sx={{ paddingTop: 1, color: 'tomato' }}>
              {frontErr}
            </Typography>
            <Typography sx={{ paddingTop: 1, color: 'tomato' }}>
              {err}
            </Typography>
          </DialogContent>
        }

        <DialogActions>
          <Button onClick={() => setDialog(false)} disabled={busy}>
            Cancel
          </Button>

          <LoadingButton
            variant='contained'
            onClick={onSave}
            sx={sxButton}
            disabled={busy || !!frontErr}
            loading={busy}
          >
            Save
          </LoadingButton>
        </DialogActions>

      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={successSnack}
        autoHideDuration={3000}
        onClose={() => setSuccessSnack(false)}
      >
        <Alert
          variant="filled"
          severity="success"
        >
          Organization Seats Updated
        </Alert>

      </Snackbar>
    </>
  )
}

const sxButton: SxProps = {
  width: '120px'
}

const sxText: SxProps = {
  marginY: 1
}
