import { ClientData } from '../../client'

export interface FinanceTableProps<T extends keyof ClientData, S extends keyof NonNullable<ClientData[T]>> {
  eventId?: string
  title: string
  type: T
  subType: S
  dataInput?: NonNullable<ClientData[T]>[S]
  datasetGroup?: any
  dynamicValues?: any
  foldEmptyTable: boolean
  hideEmptyTable: boolean
  readOnly: boolean
  onUpdate: (type: T, subType: S, updated: NonNullable<ClientData[T]>[S]) => void
}

export type FinanceTableWithMemberIdProps<T extends keyof ClientData, S extends keyof NonNullable<ClientData[T]>> = Omit<
  FinanceTableProps<T, S>,
  "onUpdate"
> & {
  memberId: string,
  onUpdate: (type: T, subType: S, updated: NonNullable<ClientData[T]>[S], memberId: string) => void;
}

export interface UpdateClientDataInterface {
  <P extends keyof ClientData, S extends keyof NonNullable<ClientData[P]>>(
    type: P,
    subType: S,
    updated: NonNullable<ClientData[P]>[S]
  ): Promise<void>;
}

export function hideTable(hideEmptyTable: boolean, data: any[] | undefined) {
  if (hideEmptyTable && (!data || data.length == 0))
    return true
  return false
}

export function hideTitle(hideEmptyTables: boolean, data: any | undefined) {
  if (hideEmptyTables) {
    if (!data) return true
    for (const [key, value] of Object.entries(data)) {
      console.log(key, (value && Array.isArray(value) && value.length > 0), value)
      if (value && Array.isArray(value) && value.length > 0) return false
    }
    return true
  }
  return false
}

export function foldTable(foldEmptyTable: boolean, data: any[] | undefined) {
  if (foldEmptyTable && (!data || data.length == 0))
    return false
  return true
}

export function getEditOptions(datasetElements: any, elementKey: string, dynamicValues: any) {
  if (!datasetElements) return []
  const edit_options = []

  const element = datasetElements[elementKey]
  const options = element.options
  const allowed_values = options.allowed_values
  if (allowed_values) {
    for (const av of allowed_values) {
      if (av)
        edit_options.push(av)
    }
  }

  const dynamicValueIds = options.dynamic_values
  if (dynamicValueIds) {
    for (const dvi of dynamicValueIds) {
      const id = dvi["dataset_id"]
      if (dynamicValues && id in dynamicValues && dynamicValues[id].values && dynamicValues[id].values.length > 0) {
        for (const v of dynamicValues[id].values) {
          edit_options.push(v)
        }
      }
    }
  }
  return edit_options
}

export function updateDynamicValues(clientData: ClientData, dynamicValueKeyMap: any) {
  for (const dvKey in dynamicValueKeyMap) {
    dynamicValueKeyMap[dvKey].values = []
    const dv: any = dynamicValueKeyMap[dvKey]
    const targetPanel = clientData[dv.panel_title as keyof ClientData] as any
    const targetGroup = targetPanel[dv.group_title]
    if (!targetGroup || targetGroup.length == 0)
      continue
    for (const elementIdx in targetGroup) {
      const elements = targetGroup[elementIdx]
      const fullName = `${elements['First Name'] ?? ""} ${elements['Last Name'] ?? ""}`.trim()
      if (fullName && elements.member_id) {
        dynamicValueKeyMap[dvKey].values.push({
          label: fullName,
          value: elements.member_id
        })
      }
    }
  }
  return dynamicValueKeyMap
}