/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_updateEnterpriseOrgSeats } from '../models/Body_updateEnterpriseOrgSeats';
import type { EnterpriseDb } from '../models/EnterpriseDb';
import type { FinmateSubscription } from '../models/FinmateSubscription';
import type { OrgBase } from '../models/OrgBase';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EnterpriseService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Enterprise
     * @returns EnterpriseDb Successful Response
     * @throws ApiError
     */
    public getEnterprise(): CancelablePromise<EnterpriseDb> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/enterprise/',
        });
    }

    /**
     * Update Enterprise Org Seats
     * @param orgId
     * @param requestBody
     * @returns EnterpriseDb Successful Response
     * @throws ApiError
     */
    public updateEnterpriseOrgSeats(
        orgId: string,
        requestBody: Body_updateEnterpriseOrgSeats,
    ): CancelablePromise<EnterpriseDb> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/enterprise/orgs/{org_id}/seats',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Org By Id
     * @param orgId
     * @returns OrgBase Successful Response
     * @throws ApiError
     */
    public getOrgById(
        orgId: string,
    ): CancelablePromise<OrgBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/enterpriseorgs/id',
            query: {
                'org_id': orgId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Subscription
     * @returns FinmateSubscription Successful Response
     * @throws ApiError
     */
    public getSubscription(): CancelablePromise<FinmateSubscription> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/enterprise/subscription',
        });
    }

}
