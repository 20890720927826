import React from 'react'
import { Typography, Skeleton, CardHeader, Card, Box } from '@mui/material'
import StripeCustomerInfo from 'components/pay/StripeCustomerInfo'
import Discount from 'components/pay/Discount'

export default function BillingOrgCard() {
  return (
    <Card sx={{ margin: 0, flexGrow: 1, paddingBottom: 0 }}>
      <CardHeader title='General Billing' />

      <StripeCustomerInfo />
      <Box sx={{ padding: 1 }} />
      <Discount />
    </Card>
  )
}