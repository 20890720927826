import _ from 'lodash'
import { Api, auth } from './api'
import { logErr, logInfo } from '../log'
import { EnterpriseDb, EnterpriseOrg, EnterpriseUserRole, FinmateSubscription, OrgBase } from 'client'
import { getErrorMessage } from 'utils'

export async function getEnterprise(): Promise<EnterpriseDb | undefined> {
  try {
    const api = await Api()
    return await api.enterprise.getEnterprise()
  } catch (e) {
    logErr('getEnterprise', { e })
  }
}

export function numStandardToOrgFromEnterprise(ent?: EnterpriseDb, org?: OrgBase) {
  if (!org || !ent)
    return 0
  const orgConfig = ent.enterprise_org_list?.find((eo: EnterpriseOrg) => eo.org_id == org.org_id)
  if (!orgConfig || !orgConfig.standard)
    return 0
  return orgConfig.standard
}

export function numStarterToOrgFromEnterprise(ent?: EnterpriseDb, org?: OrgBase) {
  if (!org || !ent)
    return 0
  const orgConfig = ent.enterprise_org_list?.find(eo => eo.org_id == org.org_id)
  if (!orgConfig || !orgConfig.starter)
    return 0
  return orgConfig.starter
}

export function numStandardAllocatedToOrgs(ent?: EnterpriseDb): number {
  if (!ent || !ent.enterprise_org_list)
    return 0

  let standardAllocated = 0
  for (const org of ent.enterprise_org_list) {
    if (org.standard)
      standardAllocated += org.standard
  }
  return standardAllocated
}

export function numStarterAllocatedToOrgs(ent?: EnterpriseDb): number {
  if (!ent || !ent.enterprise_org_list)
    return 0

  let starterAllocated = 0
  for (const org of ent.enterprise_org_list) {
    if (org.starter)
      starterAllocated += org.starter
  }
  return starterAllocated
}

export async function updateEnterpriseOrgSeats(org_id: string, standard: number, starter: number): Promise<EnterpriseDb | Error> {
  try {
    const api = await Api()
    return await api.enterprise.updateEnterpriseOrgSeats(org_id, {
      standard, starter
    })
  } catch (e) {
    const err = getErrorMessage(e)
    logErr('updateEnterpriseOrgSeats', { err })
    return err
  }
}

export async function getEnterpriseSubscription(): Promise<FinmateSubscription | undefined> {
  try {
    const api = await Api()
    return await api.enterprise.getSubscription()
  } catch (e) {
    logErr('getEnterpriseSubscription', { e })
  }
}

// export async function createEnterpriseSubscription(numStandard: number, numStarter: number, enterprise_id?: string) {
//   TODO 
//   try {
//     if (!enterprise_id)
//       throw new Error('missing enterprise id')

//     const api = await Api()
//     return await api.enterprise.createSubscription(enterprise_id, numStandard, numStarter)
//   } catch (e) {
//     const err = getErrorMessage(e)
//     logErr('createEnterpriseSubscription', { e })
//     return err
//   }
// }

// export async function updateEnterpriseSubscription(numStandard: number, numStarter: number, enterprise_id?: string) {
//   TODO
//   try {
//     if (!enterprise_id)
//       throw new Error('missing enterprise id')

//     const api = await Api()
//     return await api.enterprise.updateSubscription(enterprise_id, numStandard, numStarter)
//   } catch (e) {
//     const err = getErrorMessage(e)
//     logErr('updateEnterpriseSubscription', { e })
//     return err
//   }
// }

export function isEnterpriseAdmin(ent?: EnterpriseDb): boolean {
  const signInUid = auth.currentUser?.uid
  if (!signInUid || !ent?.users)
    return false

  for (const u of ent.users) {
    if (u.uid == signInUid)
      return u.role == EnterpriseUserRole.ADMIN
  }
  return false
}

export function isEnterpriseViewer(ent?: EnterpriseDb): boolean {
  const signInUid = auth.currentUser?.uid
  if (!signInUid || !ent?.users)
    return false

  for (const u of ent.users) {
    if (u.uid == signInUid)
      return (u.role == EnterpriseUserRole.ADMIN || u.role == EnterpriseUserRole.VIEWER)
  }
  return false
}